import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { courses } from "../../../config";

const EditLmsVideos = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [formData, setFormData] = useState<any>({
    title: "",
    courseName: "",
    uploadVideo: [],
    batchName: "",
  });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (state) {
      setFormData(state);
    }
  }, [state]);

  const handleInput = (e: any, index?: number) => {
    const { name, value } = e.target;

    if (name !== "uploadVideo") {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (name === "uploadVideo" && typeof index === "number") {
      const newTasks = [...formData.uploadVideo]; // Use the correct array field
      newTasks[index] = value;
      setFormData((prevState: any) => ({
        ...prevState,
        uploadVideo: newTasks,
      }));
    }

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  // const handleFileInput = (e: any) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setFormData((prevState: any) => ({
  //       ...prevState,
  //       uploadVideo: file,
  //     }));
  //     setErrors((prevErrors: any) => ({
  //       ...prevErrors,
  //       uploadVideo: "",
  //     }));
  //   }
  // };

  const addTask = () => {
    setFormData((prevState: any) => ({
      ...prevState,
      uploadVideo: [...prevState.uploadVideo, ""], // Add an empty string to the array
    }));
  };

  const removeTask = (index: any) => {
    const newTasks = [...formData.uploadVideo]; // Use the correct array field
    newTasks.splice(index, 1);
    setFormData((prevState: any) => ({
      ...prevState,
      uploadVideo: newTasks,
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.title) {
      newErrors.title = "Title is required";
    }

    if (!formData.courseName) {
      newErrors.courseName = "Course name is required";
    }

    if (
      formData.uploadVideo.length === 0 ||
      formData.uploadVideo.includes("")
    ) {
      newErrors.uploadVideo = "Upload Video is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validate()) return;

    formData.lmsId = state._id;

    AdminService.editLms(formData)
      .then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/support/lmsvideolist");
        }
      })
      .catch((err) => {
        toast.error("An error occurred while creating LMS Video");
      });
  };

  const handleCancel = () => {
    navigate("/support/lmsvideolist");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Edit LMS Videos</b>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Add title</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.title ? "is-invalid" : ""
                    }`}
                    placeholder="Add title"
                    name="title"
                    value={formData?.title}
                    onChange={handleInput}
                  />
                  {errors.title && (
                    <div className="invalid-feedback">{errors.title}</div>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Batch Name</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.batchName ? "is-invalid" : ""
                    }`}
                    placeholder="Batch Name"
                    name="batchName"
                    onChange={handleInput}
                    value={formData?.batchName}
                  />
                  {errors.batchName && (
                    <div className="invalid-feedback">{errors.batchName}</div>
                  )}
                </div>
              </div>
              {/* <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Video Url</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.uploadVideo ? "is-invalid" : ""
                    }`}
                    placeholder="Video Url"
                    name="uploadVideo"
                    onChange={handleInput}
                    accept="video/*" // Ensure that only video files are accepted
                  />
                  {errors.uploadVideo && (
                    <div className="invalid-feedback">{errors.uploadVideo}</div>
                  )}
                </div>
              </div> */}
              <div className="col-md-12">
                <h5 className="mt-4">
                  <b>Video Url *</b>
                </h5>
                {formData?.uploadVideo.map((task: any, index: any) => (
                  <div key={index} className="d-flex mb-3">
                    <input
                      type="text"
                      placeholder="uploadVideo"
                      name="uploadVideo"
                      className={`form-control mt-2 ${
                        errors.uploadVideo ? "is-invalid" : ""
                      }`}
                      value={task}
                      onChange={(e) => handleInput(e, index)}
                    />
                    <button
                      type="button"
                      className="ms-2 btn btn-danger"
                      onClick={() => removeTask(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addTask}
                >
                  Add Videos
                </button>
                {errors.uploadVideo && (
                  <div className="invalid-feedback d-block mt-2">
                    {errors.uploadVideo}
                  </div>
                )}
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Course Name</label>
                  <select
                    className={`form-control mt-2 ${
                      errors.courseName ? "is-invalid" : ""
                    }`}
                    onChange={handleInput}
                    name="courseName"
                  >
                    <option value="">{formData?.courseName}</option>
                    {courses.map((ele: any, index: number) => (
                      <option value={ele.name} key={index}>
                        {ele.name}
                      </option>
                    ))}
                  </select>
                  {errors.courseName && (
                    <div className="invalid-feedback">{errors.courseName}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button type="submit" className="btn btn-primary me-2">
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditLmsVideos;
