import React from 'react'
import EduExposeDifference from './../../assets/images/eduexpose-difference.png';
import EduExposeGif from './../../assets/images/videos/EduexposeGIF.gif';

export const EduexposeDifference = () => {
    const videoUrl = "https://www.youtube.com/watch?v=7WrHNF9KKso"

    return (
        <div className='px-2 mx-md-5'>
            <div className='row'>
                <div className='col-md-6 d-flex justify-content-center d-md-flex justify-content-start'>
                    <img src={EduExposeGif} className='w-75 mb-3 diff-video'/>
                </div>
                <div className='col-md-6 text-start'>
                    <div className='medium-header'>Eduexpose <span className='primary-color'>Difference</span></div>
                    <p className='secondary-color mt-2 mb-3'>
                    we in eduexpose helping the students to get exposure by working on high-quality projects and got certified which helps to crack a great package.
                    </p>
                    <p className='secondary-color'>
                    for every student in eduexpose we as a helping hand to unleash their skills in certain top-notch technologies and make them comptent to reach their goals  with life time access of support.
                    </p>
                </div>
            </div>
        </div>
    )
}
