import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components/tables";
import { ManageCourseDataGridCols } from "./data-grid-cols";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const ManageCourseList = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState([]);
  let usersData: any = [];

  const handleCancel = () => {
    setName("");
  };
  const handleSearch = () => {};
  const handleCreateUser = () => {
    navigate("/support/createcourse");
  };

  useEffect(() => {
    getCoursesList();
  }, []);

  const getCoursesList = () => {
    const data = {
      search: "",
      start: 1,
      count: 20,
    };
    AdminService.getCourseList(data).then((res: any) => {
      if (res.status === "Success") {
        setCourseData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteCourseInfo = (courseId: string) => {
    const payload = {
      courseId: courseId,
    };
    AdminService.deleteCourse(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getCoursesList();
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleEditCourse = (data: any) => {
    navigate("/support/editCourse", { state: data });
  };
  

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 mt-3">
            <label className="form-label d-flex justify-content-start text-start">
              <b>
                {" "}
                <h5> Manage Course List</h5>
              </b>
            </label>
            <div className="d-flex">
              <input
                name="User Name"
                onChange={(e: any) => setName(e.target.value)}
                className="form-control mt-1 me-2"
                value={name}
                placeholder="Enter the Course Name"
              />
              <button
                className="btn btn-primary rounded px-4 py-1"
                onClick={() => handleSearch()}
              >
                Search
              </button>
              <button
                className="btn btn-secondary rounded ms-2 px-4 py-1"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="text-end">
              <button className="btn btn-success" onClick={handleCreateUser}>
                Create Course
              </button>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <DataTable
            tableData={courseData}
            TableCols={ManageCourseDataGridCols}
            deleteCourseInfo={deleteCourseInfo}
            handleEditCourse={handleEditCourse}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageCourseList;
