import React, { useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { courses } from "../../../config";

const CreateProject = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({
    courseName: "",
    projectName: "",
    submissionDate: null,
  });
  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.courseName) {
      newErrors.courseName = "Course Name is required";
    }

    if (!formData.projectName) {
      newErrors.projectName = "Project Name is required";
    }

    if (!formData.submissionDate) {
      newErrors.submissionDate = "Submission Date is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validate()) return;

    const payload = {
      courseName: formData.courseName,
      projectName: formData.projectName,
      submissionDate: formData.submissionDate,
    };

    AdminService.createProject(payload).then((res: any) => {
      if (res.status === "Fail") {
        toast.error(res.message);
      } else {
        toast.success("Project Created Successfully");
        navigate("support/projectlist");
      }
    });
  };

  const handleCancel = () => {
    navigate("/support/projectlist");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Create Project</b>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Domain Of Interest</label>
                  <select
                    className={`form-control mt-2 ${
                      errors.courseName ? "is-invalid" : ""
                    }`}
                    onChange={handleInput}
                    name="courseName"
                  >
                    <option>Select Courses</option>
                    {courses.map((ele: any, index) => {
                      return (
                        <option value={ele.name} key={index}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.courseName && (
                    <div className="invalid-feedback">{errors.courseName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Project Name</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.projectName ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Project Name"
                    name="projectName"
                    onChange={handleInput}
                  />
                  {errors.projectName && (
                    <div className="invalid-feedback">{errors.projectName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Submission Date</label>
                  <input
                    type="date"
                    className={`form-control mt-2 ${
                      errors.submissionDate ? "is-invalid" : ""
                    }`}
                    placeholder="Enter submissionDate Name"
                    name="submissionDate"
                    onChange={handleInput}
                  />
                  {errors.submissionDate && (
                    <div className="invalid-feedback">
                      {errors.submissionDate}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button type="submit" className="btn btn-primary me-2">
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
