import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo_two from './../assets/images/Frame.png';

interface Props {
    activeMenu?: any;
}

export const NavBar = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
        scrollToTop(); // Call scrollToTop when a menu item is clicked
    };

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <nav className={`navbar navbar-expand-lg  d-flex justify-content-center sticky p-0 ${isSticky ? 'sticky' : ''}`}>
                <div className="container-xl d-flex justify-content-between">
                    <button className="navbar-toggler order-1" type="button" onClick={toggleNavbar}>
                        <span className="navbar-toggler-icon text-white bg-white"></span>
                    </button>
                    <a className="navbar-brand text-white fw-bold card-primary-bg">
                        <div onClick={handleClick} style={{ cursor: "pointer" }}>
                            <img src={Logo_two} className='' style={{ height: "50px", width: "100%" }} />
                        </div>
                    </a>
                    <div className={`collapse navbar-collapse mx-auto float-center ${isOpen ? 'show' : ''}`} id="navbarTogglerDemo03">
                        <ul className="navbar-nav me-auto ms-auto mb-2 mb-lg-0 d-flex justify-content-center menu-margin">
                            <Link to={"/home"} className='text-decoration-none'>
                                <li className="nav-item position-relative">
                                    <a className={`nav-link nav-header ${props?.activeMenu === "home" ? "active" : "in-active"}`} onClick={handleClick}>Home</a>
                                </li>
                            </Link>
                            <Link to={"/about-us"} className='text-decoration-none'>
                                <li className="nav-item">
                                    <a className={`nav-link nav-header ${props?.activeMenu === "aboutus" ? "active" : "in-active"}`} onClick={handleClick}>About Us</a>
                                </li>
                            </Link>
                            <Link to={"/programs"} className='text-decoration-none'>
                                <li className="nav-item">
                                    <a className={`nav-link nav-header ${props?.activeMenu === "programs" ? "active" : "in-active"}`} onClick={handleClick}>Programs</a>
                                </li>
                            </Link>
                            <Link to={"/ambassador"} className='text-decoration-none'>
                                <li className="nav-item">
                                    <a className={`nav-link nav-header ${props?.activeMenu === "ambassador" ? "active" : "in-active"}`} onClick={handleClick}>Ambassador</a>
                                </li>
                            </Link>
                        </ul>
                        <div className={`d-flex mt-3 ms-3 ${isSticky ? 'pe-3 my-3' : 'pe-3 my-3'}`}>
                            <Link to="/sign-in" className='text-decoration-none'>
                                <button className="btn btn-outline-light border-start-1 shadow rounded-0 px-4 primary-btn" type="submit">
                                    <span>Login</span>
                                    <span className='m-2'><i className="bi bi-arrow-right"></i></span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};
