import React, { useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
// import { uploadImage } from "../../../app/service/uploadImage";

const CreateCourse = () => {
  const [courseData, setCourseData] = useState({
    image: "",
    courseName: "",
    description: "",
    about: "",
    participationCertification: "",
    trainingCompletioncertification: "",
    internshipCompletioncertification: "",
    curriculum: [
      {
        category: "",
        topics: [""],
      },
    ],
    startDate: "",
    instructorName: "",
    enrollBy: "",
    amount: "",
    totalSeats: "",
    soldSeats: "",
    trending:false
  });

  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState("");

  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setCourseData({
      ...courseData,
      [name]: value,
    });

    validateField(name, value);
  };

  const handleFileChange = (e: any) => {
    // if (e.target.files && e.target.files[0]) {
    //   setCourseData({
    //     ...courseData,
    //     image: e.target.files[0],
    //   });
    // }
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setPhotos(file);
    };

    reader.readAsDataURL(file);
  };

  const handleCurriculumChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    const newCurriculum = [...courseData.curriculum];

    newCurriculum[index] = {
      ...newCurriculum[index],
      [name]: value,
    };

    setCourseData({
      ...courseData,
      curriculum: newCurriculum,
    });

    validateField(name, value);
  };

  const handleTopicsChange = (
    index: number,
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    const newCurriculum = [...courseData.curriculum];
    newCurriculum[index].topics = value.split("\n");
    setCourseData({
      ...courseData,
      curriculum: newCurriculum,
    });

    validateField(`topics-${index}`, value);
  };

  const addCategory = () => {
    setCourseData({
      ...courseData,
      curriculum: [
        ...courseData.curriculum,
        {
          category: "",
          topics: [""],
        },
      ],
    });
  };

  const removeCategory = (index: number) => {
    const newCurriculum = courseData.curriculum.filter(
      (category, catIndex) => catIndex !== index
    );
    setCourseData({
      ...courseData,
      curriculum: newCurriculum,
    });
  };

  const addTopic = (index: number) => {
    const newCurriculum = [...courseData.curriculum];
    newCurriculum[index].topics.push("");
    setCourseData({
      ...courseData,
      curriculum: newCurriculum,
    });
  };

  const removeTopic = (index: number, topicIndex: number) => {
    const newCurriculum = [...courseData.curriculum];
    newCurriculum[index].topics = newCurriculum[index].topics.filter(
      (topic, tIndex) => tIndex !== topicIndex
    );
    setCourseData({
      ...courseData,
      curriculum: newCurriculum,
    });
  };

  const validateField = (name: string, value: string) => {
    const newErrors: any = { ...errors };

    if (!value.trim()) {
      newErrors[name] = `${name} is required`;
    } else {
      delete newErrors[name];
    }

    setErrors(newErrors);
  };

  const validate = () => {
    const newErrors: any = {};

    // if (!courseData.image) {
    //   newErrors.image = "Image is required";
    // }
    if (!courseData.courseName) {
      newErrors.courseName = "Course Name is required";
    }
    if (!courseData.description) {
      newErrors.description = "Description is required";
    }
    if (!courseData.about) {
      newErrors.about = "About is required";
    }
    if (!courseData.participationCertification) {
      newErrors.participationCertification =
        "Participation Certification is required";
    }
    if (!courseData.trainingCompletioncertification) {
      newErrors.trainingCompletioncertification =
        "Training Completion Certification is required";
    }
    if (!courseData.internshipCompletioncertification) {
      newErrors.internshipCompletioncertification =
        "Internship Completion Certification is required";
    }
    if (!courseData.startDate) {
      newErrors.startDate = "Start Date is required";
    }
    if (!courseData.instructorName) {
      newErrors.instructorName = "Instructor Name is required";
    }
    if (!courseData.enrollBy) {
      newErrors.enrollBy = "Enroll By date is required";
    }
    if (!courseData.amount) {
      newErrors.amount = "Course Amount is required";
    }
    if (!courseData.totalSeats) {
      newErrors.totalSeats = "Total Seats are required";
    }
    if (!courseData.soldSeats) {
      newErrors.soldSeats = "Sold Seats are required";
    }

    courseData.curriculum.forEach((category, index) => {
      if (!category.category) {
        newErrors[`category-${index}`] = `Category ${index + 1} is required`;
      }
      category.topics.forEach((topic, topicIndex) => {
        if (!topic.trim()) {
          newErrors[`topic-${index}-${topicIndex}`] = `Topic ${
            topicIndex + 1
          } in Category ${index + 1} is required`;
        }
      });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e?: any) => {
    e.preventDefault();
    if (!validate()) return; // Assuming validate() checks form validation

    setLoading(true);

    try {
      const formData = new FormData();

      // Append all fields to formData
      formData.append("image", photos);
      formData.append("courseName", courseData.courseName);
      formData.append("description", courseData.description);
      formData.append("about", courseData.about);
      formData.append(
        "participationCertification",
        courseData.participationCertification
      );
      formData.append(
        "trainingCompletioncertification",
        courseData.trainingCompletioncertification
      );
      formData.append(
        "internshipCompletioncertification",
        courseData.internshipCompletioncertification
      );
      formData.append("curriculum", JSON.stringify(courseData.curriculum)); // Ensure courseData.curriculum is an array
      formData.append("startDate", courseData.startDate); // Serialize Date if necessary
      formData.append("instructorName", courseData.instructorName);
      formData.append("enrollBy", courseData.enrollBy); // Serialize Date if necessary
      formData.append("amount", courseData.amount);
      formData.append("totalSeats", courseData.totalSeats);
      formData.append("soldSeats", courseData.soldSeats);
      formData.append("trending", String(courseData.trending));


      const res = await AdminService.createCourse(formData);
      setLoading(false);

      if (res.status === "Fail") {
        toast.error(res.error);
      } else {
        toast.success("Course successfully created");
        navigate("/dashboard");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while creating the course");
      console.error("Error creating course:", error);
    }
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Create Course</b>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 pt-2 pb-2">
                <label htmlFor="image">Image</label>
                <input
                  type="file"
                  name="image"
                  id="image"
                  className={`form-control ${errors.image ? "is-invalid" : ""}`}
                  onChange={handleFileChange}
                />
                {errors.image && (
                  <div className="invalid-feedback">{errors.image}</div>
                )}
              </div>
              <div className="col-md-6 pt-2 pb-2">
                <label htmlFor="courseName">Course Name</label>
                <input
                  type="text"
                  name="courseName"
                  id="courseName"
                  className={`form-control ${
                    errors.courseName ? "is-invalid" : ""
                  }`}
                  value={courseData.courseName}
                  onChange={handleChange}
                />
                {errors.courseName && (
                  <div className="invalid-feedback">{errors.courseName}</div>
                )}
              </div>
              <div className="col-md-12 pt-2 pb-2">
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  className={`form-control ${
                    errors.description ? "is-invalid" : ""
                  }`}
                  value={courseData.description}
                  onChange={handleChange}
                />
                {errors.description && (
                  <div className="invalid-feedback">{errors.description}</div>
                )}
              </div>
              <div className="col-md-12 pt-2 pb-2">
                <label htmlFor="about">About</label>
                <textarea
                  name="about"
                  id="about"
                  className={`form-control ${errors.about ? "is-invalid" : ""}`}
                  value={courseData.about}
                  onChange={handleChange}
                />
                {errors.about && (
                  <div className="invalid-feedback">{errors.about}</div>
                )}
              </div>
              <div className="col-md-12 pt-2 pb-2">
                <label htmlFor="participationCertification">
                  Participation Certification
                </label>
                <input
                  type="text"
                  name="participationCertification"
                  id="participationCertification"
                  className={`form-control ${
                    errors.participationCertification ? "is-invalid" : ""
                  }`}
                  value={courseData.participationCertification}
                  onChange={handleChange}
                />
                {errors.participationCertification && (
                  <div className="invalid-feedback">
                    {errors.participationCertification}
                  </div>
                )}
              </div>
              <div className="col-md-12 pt-2 pb-2">
                <label htmlFor="trainingCompletioncertification">
                  Training Completion Certification
                </label>
                <input
                  type="text"
                  name="trainingCompletioncertification"
                  id="trainingCompletioncertification"
                  className={`form-control ${
                    errors.trainingCompletioncertification ? "is-invalid" : ""
                  }`}
                  value={courseData.trainingCompletioncertification}
                  onChange={handleChange}
                />
                {errors.trainingCompletioncertification && (
                  <div className="invalid-feedback">
                    {errors.trainingCompletioncertification}
                  </div>
                )}
              </div>
              <div className="col-md-12 pt-2 pb-2">
                <label htmlFor="internshipCompletioncertification">
                  Internship Completion Certification
                </label>
                <input
                  type="text"
                  name="internshipCompletioncertification"
                  id="internshipCompletioncertification"
                  className={`form-control ${
                    errors.internshipCompletioncertification ? "is-invalid" : ""
                  }`}
                  value={courseData.internshipCompletioncertification}
                  onChange={handleChange}
                />
                {errors.internshipCompletioncertification && (
                  <div className="invalid-feedback">
                    {errors.internshipCompletioncertification}
                  </div>
                )}
              </div>
              {courseData.curriculum.map((category, index) => (
                <div className="col-md-12 pt-2 pb-2" key={index}>
                  <label htmlFor={`category-${index}`}>
                    Category {index + 1}
                  </label>
                  <input
                    type="text"
                    name="category"
                    id={`category-${index}`}
                    className={`form-control ${
                      errors[`category-${index}`] ? "is-invalid" : ""
                    }`}
                    value={category.category}
                    onChange={(e) => handleCurriculumChange(index, e)}
                  />
                  {errors[`category-${index}`] && (
                    <div className="invalid-feedback">
                      {errors[`category-${index}`]}
                    </div>
                  )}
                  <label htmlFor={`topics-${index}`}>Topics</label>
                  <textarea
                    name={`topics-${index}`}
                    id={`topics-${index}`}
                    className={`form-control ${
                      errors[`topics-${index}`] ? "is-invalid" : ""
                    }`}
                    value={category.topics.join("\n")}
                    onChange={(e) => handleTopicsChange(index, e)}
                  />
                  {errors[`topics-${index}`] && (
                    <div className="invalid-feedback">
                      {errors[`topics-${index}`]}
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn btn-danger mt-2"
                    onClick={() => removeCategory(index)}
                  >
                    Remove Category
                  </button>
                </div>
              ))}
              <div className="col-md-12 pt-2 pb-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addCategory}
                >
                  Add Category
                </button>
              </div>
              <div className="col-md-6 pt-2 pb-2">
                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  className={`form-control ${
                    errors.startDate ? "is-invalid" : ""
                  }`}
                  value={courseData.startDate}
                  onChange={handleChange}
                />
                {errors.startDate && (
                  <div className="invalid-feedback">{errors.startDate}</div>
                )}
              </div>
              <div className="col-md-6 pt-2 pb-2">
                <label htmlFor="instructorName">Instructor Name</label>
                <input
                  type="text"
                  name="instructorName"
                  id="instructorName"
                  className={`form-control ${
                    errors.instructorName ? "is-invalid" : ""
                  }`}
                  value={courseData.instructorName}
                  onChange={handleChange}
                />
                {errors.instructorName && (
                  <div className="invalid-feedback">
                    {errors.instructorName}
                  </div>
                )}
              </div>
              <div className="col-md-6 pt-2 pb-2">
                <label htmlFor="enrollBy">Enroll By</label>
                <input
                  type="date"
                  name="enrollBy"
                  id="enrollBy"
                  className={`form-control ${
                    errors.enrollBy ? "is-invalid" : ""
                  }`}
                  value={courseData.enrollBy}
                  onChange={handleChange}
                />
                {errors.enrollBy && (
                  <div className="invalid-feedback">{errors.enrollBy}</div>
                )}
              </div>
              <div className="col-md-6 pt-2 pb-2">
                <label htmlFor="amount">Course Amount</label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  className={`form-control ${
                    errors.amount ? "is-invalid" : ""
                  }`}
                  value={courseData.amount}
                  onChange={handleChange}
                />
                {errors.amount && (
                  <div className="invalid-feedback">{errors.amount}</div>
                )}
              </div>
              <div className="col-md-6 pt-2 pb-2">
                <label htmlFor="totalSeats">Total Seats</label>
                <input
                  type="number"
                  name="totalSeats"
                  id="totalSeats"
                  className={`form-control ${
                    errors.totalSeats ? "is-invalid" : ""
                  }`}
                  value={courseData.totalSeats}
                  onChange={handleChange}
                />
                {errors.totalSeats && (
                  <div className="invalid-feedback">{errors.totalSeats}</div>
                )}
              </div>
              <div className="col-md-6 pt-2 pb-2">
                <label htmlFor="totalSeats">Total Sold Seats</label>
                <input
                  type="number"
                  name="soldSeats"
                  id="soldSeats"
                  className={`form-control ${
                    errors.soldSeats ? "is-invalid" : ""
                  }`}
                  value={courseData.soldSeats}
                  onChange={handleChange}
                />
                {errors.soldSeats && (
                  <div className="invalid-feedback">{errors.soldSeats}</div>
                )}
              </div>
              <div className="col-md-6 pt-2 pb-2">
                <label htmlFor="trending">Course Trending</label>
                <select
                  name="trending"
                  id="trending"
                  className={`form-control ${
                    errors.trending ? "is-invalid" : ""
                  }`}
                  value={courseData.trending ? "true" : "false"}
                  onChange={handleChange}
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                {errors.trending && (
                  <div className="invalid-feedback">{errors.trending}</div>
                )}
              </div>

              <div className="col-md-12 pt-2 pb-2">
                <button
                  type="submit"
                  className="btn btn-success me-4"
                  disabled={loading}
                >
                  {loading ? "Creating..." : "Create Course"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary ml-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCourse;
