import React from 'react'

export const OurVision = () => {
    return (
        <div className='px-2 mx-md-5 '>
            <div className='medium-header'>Our <span className='primary-color'>Vision</span></div>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='secondary-color py-3'>
                    Our vision is to make learning available to everyone. We want to help people discover their talents, achieve their goals, and make a positive impact in their communities. Using fun and easy-to-use technology, we aim to create exciting learning experiences that inspire and empower learners of all ages.
                    </div>
                </div>
            </div>
        </div>
    )
}
