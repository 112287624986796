import { DataTableCol } from "../../../components/tables/types";

export const LmsVideoDataGridCols: DataTableCol[] = [
    {
        title: 'Title',
        control: 'title',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Course Name',
        control: 'courseName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Video Link',
        control: 'uploadVideo',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        canShowColumn: true
    },
];