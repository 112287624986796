import { DataTableCol } from "../../../components/tables/types";

export const QueriesDataGridCols: DataTableCol[] = [

    {
        title: 'Email',
        control: 'email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Date',
        control: 'createdAt',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        // isEdit: true,
        isDelete: true,
        canShowColumn: true
    },
];