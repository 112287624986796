import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";
import projectImg from "../../../assets/images/user_cerificates/projects.jpg";

const UserInternships = () => {
  const userdata: any = sessionStorage.getItem("loginData");
  const userRole = JSON.parse(userdata);
  const userCourses = userRole.courseName;
  const [projectsData, setProjectsData] = useState([]);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };

    // showLoader();

    AdminService.getallProjectsList(data)
      .then((res: any) => {
        if (res.status === "Success") {
          const userdata: any = sessionStorage.getItem("loginData");
          const userRole = JSON.parse(userdata);
          const userCourses = userRole.courseName;
          const filteredData = res.data.filter((meet: any) =>
            userCourses.includes(meet.courseName)
          );
          setProjectsData(filteredData);
        } else {
          toast.error(res.message);
        }
        // hideLoader();
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
        // hideLoader();
      });
  };

  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          
            {projectsData?.length > 0 ? (
              <>
              <div className="col-md-10 content-scroll content-bg mt-5 px-5">
                <div className="row">
                  <div className="col-md-8">
                    <div className="card">
                      <div className="px-5 py-5">
                        <h4 className="">
                          <b>{userCourses[0]}</b>
                        </h4>
                        <div>
                          <div className="progress" style={{ width: "50%" }}>
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "50%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              50%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-5">
                      <div className="px-5 py-5 text-left">
                        {projectsData.map((item: any, index: number) => {
                          return (
                            <>
                              <h4 className="" key={index}>
                                <b>{item.projectName}</b>
                              </h4>
                              <h6 className="">
                                Submission Date:-{" "}
                                {moment(item.submissionDate).format(
                                  "DD-MMM-YYYY"
                                )}
                              </h6>
                            </>
                          );
                        })}

                        <h6 className="mt-2">Upload Project :</h6>
                        <input type="file" />
                        <br />
                        <button className="btn btn-success text-left mt-3">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-10 content-scroll content-bg px-5 text-center mt-5">
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <img src={projectImg} width="30%" height="50%" className="mt-5"/>
                  <h4 className="">
                  <b>Empty <span className="primary-color">Internship</span></b>
                  </h4>
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default UserInternships;
