import React from "react";
import RoadMap from "./../../assets/images/Roadmap.png";
import RoadMap_two from "./../../assets/images/Roadmap_two.png";
import { useNavigate } from "react-router-dom";

export const InternshipRoadMap = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRegister = () => {
    navigate("/registernow");
    scrollToTop();
  };
  return (
    <div className="px-2 mx-md-5">
      <div className="d-md-flex justify-content-between mb-4">
        <div className="text-start">
          <div className="medium-header">
            Internship <span className="primary-color">Roadmap</span>
          </div>
          <div className="secondary-color">
            Accelerate your career with our internship roadmap, offering
            hands-on experience and mentorship
          </div>
        </div>
        <div className="mt-3 text-start">
          <button
            className="bg-transparent text-white border border-white px-4 py-1 primary-btn"
            onClick={handleRegister}
          >
            Register now
          </button>
        </div>
      </div>
      <div className="mt-4 d-none d-md-block mb-5 mx-0 px-0">
        <img src={RoadMap_two} className="road-map-image" />
      </div>
      <div className="d-block d-md-none">
        <div>
          <div className="fs-3 my-2 text-start fw-bold primary-color">
            Register
          </div>
          <div className="secondary-color text-start mb-3">
            Quick and easy registration grants access to our platform's
            personalized dashboard,{" "}
          </div>
        </div>
        <div>
          <div className="fs-3 my-2 text-start fw-bold primary-color">
            Login
          </div>
          <div className="secondary-color text-start mb-3">
            users can track progress and engage with resources in dashboard
          </div>
        </div>
        <div>
          <div className="fs-3 my-2 text-start fw-bold primary-color">
            Live Industry Exposure
          </div>
          <div className="secondary-color text-start mb-3">
            Partnering with top companies, we provide access to real-world
            projects and simulations, offering invaluable insights into industry
            challenges
          </div>
        </div>
        <div>
          <div className="fs-3 my-2 text-start fw-bold primary-color">
            Project Submission
          </div>
          <div className="secondary-color text-start mb-3">
            Users apply their knowledge by completing a project, supported by
            our guidance and resources, showcasing their skills in their chosen
            field
          </div>
        </div>
        <div>
          <div className="fs-3 my-2 text-start fw-bold primary-color">
            Certification
          </div>
          <div className="secondary-color text-start mb-3">
            Upon successful project submission, industry experts evaluate users'
            proficiency, granting certification from companies
          </div>
        </div>
      </div>
    </div>
  );
};
