import React from "react";
import { NavBar } from "../nav-bar";

const Privacy = () => {
  return (
    <div className="  pt-5">
      <div className="row">
        <div className="col-md-12 ">
          <NavBar />
        </div>
      </div>
      <div className="container">
        <div className="col-md-12  mt-3 py-4 my-md-5 py-md-4 text-start">
          <h1>PRIVACY POLICY</h1>
          <p className="mt-3">
            Eduexpose is owned and operated by Eduexpose(“We”, “Us”, “Eduexpose
            ”, “Company” or “Our”, which expression shall mean and include its
            affiliates, successors and assigns). This Policy constitutes a legal
            agreement between You, as the user of the Website, and Eduexpose ,
            as the owner of the Website. This Policy does not apply to
            third-party websites that are connected via links to the Website. We
            encourage you to take the time to read this Privacy Policy as well
            as those of any other sites you visit, whether through a link on
            that site or otherwise.
          </p>
          <p className="mt-3">
            BY USING THE COMPANY WEBSITE, OR BY OTHERWISE GIVING US YOUR
            INFORMATION, YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED
            TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND
            AGREE TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR
            COLLECTION, USE AND SHARING, DISCLOSURE OF YOUR INFORMATION AS
            DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE RIGHT TO CHANGE,
            MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY POLICY,
            AT OUR SOLE DISCRETION, AT ANY TIME.
          </p>
          <h3 className="mt-3">APPLICABILITY</h3>
          <p className="mt-3">
            This privacy policy (“Policy”) describes Our policies and procedures
            on the collection, use, storage and disclosure of any information
            including, business or personal information provided by You while
            using Our Website.
          </p>
          <h3 className="mt-3">COLLECTION OF INFORMATION:</h3>
          <p className="mt-3">Personally Identifiable Information</p>
          <p className="mt-3">
            Eduexpose will use the information provided by You only in
            accordance with the purposes described in the Policy. During Your
            use of Our Website, We may collect and process such information from
            You, including but not limited to the below mentioned:
          </p>
          <p className="mt-3">
            Information that You provide to Us by filling in forms on the
            Website. This includes contact information such as name, email
            address, mailing address, phone number, any financial information,
            if any, unique identifiers such as user name, account number and
            password.
          </p>
          <p className="mt-3">
            Information that You provide when You write directly to Us
            (including by e-mail).
          </p>
          <p className="mt-3">
            Information that You provide to Us over telephone. We may make and
            keep a record of the information You share with us;
          </p>
          <p className="mt-3">
            Information that You provide to Us by completing surveys.
          </p>
          <h4 className="mt-3">Non Personal Information-</h4>
          <p className="mt-3">
            When You use the Website, Our servers automatically record certain
            information that Your web browser sends whenever You visit any
            website. These server logs may include information such as Your web
            request, Internet Protocol (IP) address, browser type, referring/
            exit pages and URLs, number of clicks, domain names, landing pages,
            pages viewed, and other such information. We use this information,
            which does not identify users, to analyze trends, to administer the
            Website, to track users’ movements around the site and to gather
            demographic information about the user base as a whole. We do not
            link this automatically-collected data to personally identifiable
            information. When You use the Website, We may employ clear web
            beacons which are used to track Your online usage patterns
            anonymously. No personally identifiable information from You is
            collected using these web beacons. In addition, We may also use
            clear web beacons in HTML-based e-mails sent to You to track which
            e-mails are opened/ viewed. Such collected information is used to
            enable more accurate reporting and making the Website better for Our
            users.
          </p>
          <h4 className="mt-3">COOKIES-</h4>
          <p className="mt-3">
            “Cookies” are small files that reside on Your computer's hard drive
            and generally contain an anonymous unique identifier and are
            accessible only by the website that placed them there and not any
            other sites. We may collect information about Your general internet
            usage by using a cookie file which is stored on the hard drive of
            Your computer. Cookies help Us improve Our Website and deliver a
            better and personalized service. Cookies enable Us: To estimate Our
            users’ size and usage pattern.
          </p>
          <p className="mt-3">
            To store information about Your preferences, and allow Us to
            customize Our Website according to Your interests.
          </p>
          <p className="mt-3">To speed up Your searches.</p>
          <p className="mt-3">
            To recognize You when You return to Our Website.
          </p>
          <p className="mt-3">
            You may refuse to accept Cookies by activating the setting on Your
            browser which allows You to refuse the setting of Cookies. However,
            if You select this setting You may be unable to access certain parts
            of Our Website. Unless You have adjusted Your browser setting so
            that it will refuse Cookies, Our system may issue Cookies when You
            log on to the Website. The use of Cookies by Our partners,
            affiliates, advertisers or service providers is not covered by the
            Policy. Eduexpose may use the services of certain third parties, for
            the purpose of operating and administering the Website. Such third
            party service providers may collect the information sent by Your
            browser as part of a web page request, including Cookies and your IP
            address and such information will be governed by the privacy
            policies of the third party service providers.
          </p>
          <h4 className="mt-3">USE OF THE INFORMATION:</h4>
          <p className="mt-3">
            We may use the information provided by You in the following ways,
            viz:
          </p>
          <p className="mt-3">Monitor, improve and administer our Website.</p>
          <p className="mt-3">Conduct research and analysis.</p>
          <p className="mt-3">
            Analyze how the Website is used, diagnose service or technical
            problems, maintain security. Remember information to help You
            efficiently access the Website.
          </p>
          <p className="mt-3">
            To ensure that content from Our Website is presented in the most
            effective manner based upon Your interests.
          </p>
          <p className="mt-3">
            To provide You with information that You request from Us.
          </p>
          <p className="mt-3">
            To carry out Our obligations arising from any contracts entered into
            between You and Us.
          </p>
          <p className="mt-3">To notify You about changes on Our Website.</p>
          <p className="mt-3">
            To enable Us to comply with Our legal and regulatory obligations.
          </p>
          <h4 className="mt-3">THIRD-PARTY SERVICES</h4>
          <p className="mt-3">
            In general, the third-party providers used by Us will only collect,
            use and disclose your information to the extent necessary to allow
            them to perform the services they provide to us. However, certain
            third-party service providers, such as payment gateways and other
            payment transaction processors, have their own privacy policies in
            respect to the information we are required to provide to them for
            your purchase-related transactions. For these providers, we
            recommend that You read their privacy policies so You can understand
            the manner in which your personal information will be handled by
            these providers. Once you leave our Website or are redirected to a
            third-party website or application, you are no longer governed by
            this Privacy Policy.
          </p>
          <h4 className="mt-3">DISCLOSURE OF INFORMATION</h4>
          <p className="mt-3">
            Eduexpose does not sell, rent or otherwise provide your personal or
            business information to third parties except as provided in this
            policy. Eduexpose may share Your information with third parties with
            Your prior consent and in such an event, the third parties' use of
            Your information will be bound by the Policy. We may store
            information in locations outside the direct control of Eduexpose
            (for instance, on servers or databases co-located with hosting
            providers).
          </p>
          <p className="mt-3">
            In the event that We sell or buy any business or assets, we may
            disclose Your information, with Your prior consent, to the
            prospective seller or buyer of such business or assets. User, email,
            and visitor information is generally one of the transferred business
            assets in these types of transactions. We may also transfer or
            assign such information in the course of corporate divestitures,
            mergers, or dissolution.
          </p>
          <p className="mt-3">
            We may disclose Your information to service providers involved in
            operating Our business. This includes payment providers, third party
            servers, email service providers and professionals such as
            accountants and lawyers.
          </p>
          <p className="mt-3">
            We reserve the right to disclose your personal information in the
            scope and in situations as required by the law and when we believe
            in good faith that such disclosure is necessary toprotect our
            rights, to report suspected illegal activity, comply with judicial
            proceedings, court orders or decisions, or legal process served on
            our website.
          </p>
          <p className="mt-3">
            We may also use Your information, and/ or permit selected third
            parties including service providers, on the Website, with Your prior
            consent, to use Your information or provide You with such
            information which may be of interest to You and We and/ or they may
            contact You about the same. If You wish to be so contacted by the
            third parties, any information You provide to such third parties may
            be read, collected, and used by them. Notwithstanding the above
            mentioned, You may choose to stop receiving such information from
            the third parties by writing to Us at.
          </p>
          <h4 className="mt-3">YOUR CHOICES ABOUT YOUR INFORMATION</h4>
          <p className="mt-3">
            You may, of course, decline to submit personally identifiable
            information through the Website, in which case Eduexpose may not be
            able to provide certain services to you. In addition, you may update
            or correct your account information and email preferences at any
            time by logging in to your account. If you would like us to remove
            your personally identifiable information from our database, please
            send a request to. We are not responsible for revising or removing
            your personally identifiable information obtained by any third party
            who has previously been provided your information by us in
            accordance with this policy or any third party to whom you have
            provided such information (whether by sharing your login and
            password, or otherwise).
          </p>
          <h4 className="mt-3">LINKS TO THIRD PARTY SITES</h4>
          <p className="mt-3">
            Our Website may, from time to time, contain links to and from the
            websites of Our partner networks, affiliates and other third
            parties. The inclusion of a link does not imply any endorsement by
            Us of the third party website, the website's provider, or the
            information on the third party website. If You follow a link to any
            of these websites, please note that these websites may be governed
            by their own privacy policies and We disclaim all responsibility or
            liability with respect to these policies or the websites. Please
            check these policies and the terms of the websites before You submit
            any information to these websites.
          </p>
          <h4 className="mt-3">INFORMATION STORAGE AND SECURITY</h4>
          <p className="mt-3">
            We have implemented a wide range of measures in order to ensure the
            security and confidentiality of your data. These include physical,
            electronic and administrative safeguards such as firewalls, data
            encryption, SSL and other up-to-date technologies.
          </p>
          <p className="mt-3">
            No security measures, however, are 100% complete. Therefore, we do
            not promise and cannot guarantee, and thus you should not expect,
            that your personal information or private communications will not be
            collected and used by others. You should take steps to protect
            against unauthorized access to your password, phone, and computer
            by, among other things, signing off after using a shared computer,
            choosing a robust password that nobody else knows or can easily
            guess, and keeping your log-in and password private. We are not
            responsible for the unauthorized use of your information or for any
            lost, stolen, compromised passwords, or for any activity on your
            Account via unauthorized password activity. We cannot guarantee the
            security of your data while it is being transmitted over the
            Internet and through servers that areout of our control. We do our
            best to protect your personal information but we cannot ensure or
            warrant the security of any information you transmit to our Website
            or Services. Therefore, if you make any data transmissions over the
            Internet, you are doing it at your own risk. Once we receive the
            data transmission, we make our best efforts to ensure its security
            and privacy on our systems.
          </p>
          <h4 className="mt-3">EXCLUSION</h4>
          <p className="mt-3">
            This Policy does not apply to any information other than such
            information collected otherwise. This Policy shall not apply to any
            unsolicited information You provide Us through this Website or
            through any other means. This includes, but is not limited to,
            information posted to any public areas of the Website. All such
            unsolicited information shall be deemed to be non- confidential and
            Eduexpose shall be free to use, disclose such unsolicited
            information without limitation
          </p>
          <h4 className="mt-3">SEVERABILITY</h4>
          <p className="mt-3">
            We have made every effort to ensure that this Policy adheres with
            the applicable laws. The invalidity or unenforceability of any part
            of this Policy shall not prejudice or affect the validity or
            enforceability of the remainder of this Policy.
          </p>
          <h4 className="mt-3">GOVERNING LAW AND DISPUTE RESOLUTION</h4>
          <p className="mt-3">
            This Policy shall be governed by and construed in accordance with
            the laws of the India and the courts at Hyderabad, Telangana shall
            have exclusive jurisdiction in relation to any disputes arising out
            of or in connection with this Policy.
          </p>
          <h4 className="mt-3">FOREIGN JURISDICTION</h4>
          <p className="mt-3">
            Eduexpose makes no representation that the content contained in the
            Website is appropriate or to be used anywhere outside of India. The
            information provided on or through the contents of the Website is
            not intended for distribution to or use by any person or entity in
            any jurisdiction or country where such distribution or use would be
            contrary to law or regulation or which would subject the Company to
            any registration requirement within such jurisdiction or country.
            Accordingly, those persons who choose to access the Services from
            other locations do so on their own initiative and are solely
            responsible for compliance with local laws, if and to the extent
            local laws are applicable.
          </p>
          <h4 className="mt-3">CHANGES TO THE PRIVACY POLICY</h4>
          <p className="mt=3">
            We may change this Policy at any time, in particular as needed to
            comply with the rules and regulations of the various governmental
            and regulatory organizations, or to comply with applicable laws and
            regulations. The revised version will be effective at the time we
            post it on website or inform to the User. If a User uses the
            Services or accesses the website after a notice of changes has been
            sent to such User or published on the Website, such User hereby
            provides his/her/its consent to the changed terms.
          </p>
          <h4 className="mt-3">CONTACT</h4>
          <p className="mt-3">
            Any required or permitted notices hereunder must be given in writing
            to the other, by one of the following methods: (a) by electronic
            mail; (b) sent by facsimile; or (c) sent by registered mail, postage
            prepaid; or (d) internationally-recognized private express courier.
            The Company's address for communication is:
          </p>
          <ul>
            <li>
              <b>Address:</b> Eduexpose Technologies Pvt Ltd,Madhapur, Hyderabad, Telangana ,500018
            </li>
            <li>
              <b>Attention:</b> Support@eduexpose.in
            </li>
            <li>
              <b>Phone No:</b>+91-7207706919{" "}
            </li>
            <li>
              <b>Address:</b> support@Eduexpose.in
            </li>
          </ul>
          <h4 className="mt-3">APPLICABLE LAW AND JURISDICTION</h4>
          <p className="mt-3">
            This Privacy Policy shall be governed by, construed and entered in
            accordance with the laws of the Republic of India, without regard to
            choice of law or conflict of law provisions thereof. All disputes
            with respect to this Privacy Policy shall be brought and heard in
            the courts situated at Hyderabad, India.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
