import React from 'react'

export const GetMoreInfo = () => {
    return (
        <div className='px-2 mx-md-5'>
            <div className='row'>
                <div className='col-md-6 mx-auto'>
                    <div className='fs-2 fw-bold my-3'>Get more <span className='primary-color'>information</span></div>
                    <div className='mb-3'>
                        <label className='form-label d-flex justify-content-start'>Name</label>
                        <input type="text" name="name" className='form-control custom-input'/>
                    </div>
                    <div className='mb-3'>
                        <label className='form-label d-flex justify-content-start'>Email</label>
                        <input type="text" name="email" className='form-control custom-input'/>
                    </div>
                    <div className='mb-3'>
                        <label className='form-label d-flex justify-content-start'>Message</label>
                        <textarea  name="message" rows={3} className='form-control custom-input'></textarea>
                    </div>
                    <div className='my-2'>
                        <input type="checkbox" className='me-2 bg-transparent border border-secondary'/>
                        <span className='secondary-color'>Send me special offers, perosnalize recommendations, and learning tips</span>
                    </div>
                    <div className='my-3'><button className='bg-white px-5 py-2 secondary-btn w-100'>Request info</button></div>
                </div>
            </div>
        </div>
    )
}
