import { DataTableCol } from "../../../components/tables/types";

export const UsersDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'firstName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Email',
        control: 'email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Phone Number',
        control: 'phone',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Role',
        control: 'roleName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Copy',
        control: 'copyName',
        sortable: false,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        canShowColumn: true
    },
];