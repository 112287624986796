import React from 'react'
import WorkOne from './../../assets/images/ambassador-works/work-one.png';
import WorkTwo from './../../assets/images/ambassador-works/work-two.png';
import WorkThree from './../../assets/images/ambassador-works/work-three.png';
import WorkFour from './../../assets/images/ambassador-works/work-four.png';
import StepOne from './../../assets/images/ambassador-works/step-one.png';
import StepTwo from './../../assets/images/ambassador-works/step-two.png';
import StepThree from './../../assets/images/ambassador-works/step-three.png';
import StepFour from './../../assets/images/ambassador-works/step-four.png';
import Line from './../../assets/images/ambassador-works/line.png';

export const AmbassdorWorks = () => {
    return (
    <div>
        <div className='px-2 mx-md-5 px-md-5 d-none d-md-block'>
            <div className='mb-4'>
                <div className='ambassador-header'>How our <span className='primary-color'>Ambassador</span> works?</div>
                {/* <div className='secondary-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </div> */}
            </div>
            <div className='row d-flex mx-auto mb-5 mb-md-0'>
                <div className='col-8 col-md-4 text-start'>
                    <div className='step-one'>SHARE WITH PEERS</div>
                    <div className='fs-6 secondary-color my-3'>Share the most exciting live projects,internships, and job opportunities with your peers.</div>
                </div>
                <div className='col-md-4 mx-auto d-none d-md-block'>
                    <div><img src={StepOne} /></div>
                    <div><img src={Line} /></div>
                </div>
                <div className='col-4 col-md-4 d-flex justify-content-end'>
                    <div><img src={WorkOne} className='img-fluid' /></div>
                </div>
            </div>
            <div className='row d-flex mx-auto my-3 mb-5 mb-md-0'>
                <div className='col-4 col-md-4 d-flex justify-content-start'>
                    <div><img src={WorkTwo} className='img-fluid' /></div>
                </div>
                <div className='col-md-4 mx-auto d-none d-md-block'>
                    <div><img src={StepTwo} /></div>
                    <div><img src={Line} /></div>
                </div>
                <div className='col-8 col-md-4 text-start d-flex justifyc-content-start'>
                    <div>
                        <div className='step-one'>ASSIST YOUR PEERS</div>
                        <div className='fs-6 secondary-color my-3'>Help your peers to learn in demand skills and get internship opportunity from affiliated organizations.</div>
                    </div>
                </div>
            </div>
            <div className='row d-flex mx-auto my-3 mb-5 mb-md-0'>
                <div className='col-8 col-md-4 text-start'>
                    <div className='step-one'>SIGNUP FOR PROJECT</div>
                    <div className='fs-6 secondary-color my-3'>Guide your colleague ,friends and peers to join the webinar for Certificate and Internship  </div>
                </div>
                <div className='col-md-4 d-none d-md-block'>
                    <div><img src={StepThree} /></div>
                    <div><img src={Line} /></div>
                </div>
                <div className='col-4 col-md-4 d-flex justify-content-end'>
                    <div><img src={WorkThree} className='img-fluid' /></div>
                </div>
            </div>
            <div className='row d-flex mx-auto my-3'>
                <div className='col-4 col-md-4 d-flex justify-content-start'>
                    <div><img src={WorkFour} className='img-fluid' /></div>
                </div>
                <div className='col-md-4 d-none d-md-block'>
                    <div><img src={StepFour} /></div>
                </div>
                <div className='col-8 col-md-4 text-start d-flex justifyc-content-start'>
                    <div>
                        <div className='step-one'>GRAB THE REWARDS</div>
                        <div className='fs-6 secondary-color my-3'>Finish the task and grab the exciting rewards such as Iphones , Cash prize and so many...</div>
                    </div>
                </div>
            </div>
        </div>

        <div className='d-block d-md-none mt-3'>
            <div className='mb-4'>
                <div className='ambassador-header'>How our <span className='primary-color'>Ambassador</span> works?</div>
                {/* <div className='secondary-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </div> */}
            </div>
            <div className="row">
                <div className="col-3 col-md-4 step-number text-center">
                    <div className="col-12">
                        <img src={StepOne} />
                    </div>
                    <div className="col-12">
                        <img src={Line} />
                    </div>
                </div>
                <div className="col-9 col-md-4 step-content ps-2 mb-2 text-start">
                    <img className="img-fluid" src={WorkOne} />
                    <h5 className="mt-2">Share with Peers</h5>
                    <p className="step-description">Share the most exciting live projects,internships, and job opportunities with your peers.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-3 col-md-4 step-number text-center">
                    <div className="col-12">
                        <img src={StepTwo} />
                    </div>
                    <div className="col-12">
                        <img src={Line} />
                    </div>
                </div>
                <div className="col-9 col-md-4 step-content ps-2 mb-2 text-start">
                    <img className="img-fluid" src={WorkTwo} />
                    <h5 className="mt-2">Assist your Peers</h5>
                    <p className="step-description">Help your peers to learn in demand skills and get internship opportunity from affiliated organizations.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-3 col-md-4 step-number text-center">
                    <div className="col-12">
                        <img src={StepThree} />
                    </div>
                    <div className="col-12">
                        <img src={Line} />
                    </div>
                </div>
                <div className="col-9 col-md-4 step-content ps-2 mb-2 text-start">
                    <img className="img-fluid" src={WorkThree} />
                    <h5 className="mt-2">Signup for Project</h5>
                    <p className="step-description">Guide your colleague ,friends and peers to join the webinar for Certificate and Internship</p>
                </div>
            </div>

            <div className="row">
                <div className="col-3 col-md-4 step-number text-center">
                    <div className="col-12">
                        <img src={StepFour} />
                    </div>
                    <div className="col-12">
                        <img src={Line} />
                    </div>
                </div>
                <div className="col-9 col-md-4 step-content ps-2 mb-2 text-start">
                    <img className="img-fluid" src={WorkFour} />
                    <h5 className="mt-2">Grab the Rewards</h5>
                    <p className="step-description">Finish the task and grab the exciting rewards such as Iphones , Cash prize and so many...</p>
                </div>
            </div>
        </div>

    </div>
    )
}
