const Loader = () => {
  return (
    <>
      <div id="loader-block" className="loader-block">
        <div className="loader-position">
          <div className="loaders"></div>
        </div>
      </div>
    </>
  );
};
export default Loader;
