import React from 'react'
import JoinCommunityImage from './../../assets/images/join-community.png';
import tcs from './../../assets/images/companies/tcs.png';
import hcl from './../../assets/images/companies/hcl.png';
import techmahendra from './../../assets/images/companies/techmahendra.png';
import microsoft from './../../assets/images/companies/microsoft.png';
import infoys from './../../assets/images/companies/infoys.png';




export const JoinCommunity = () => {
    return (
        <div className='px-2 mx-md-5'>
            <div className='row mb-0 mb-sm-5'>
                <div className='col-md-6 text-start'>
                    <div className='medium-header'>Join our <span className='primary-color'>community</span> of<br/>learners</div>
                    <div className='secondary-color pt-3'>Our inclusive community caters to engineering students, fostering collaborative learning and support. Engage with peers to deepen your understanding and skills in engineering disciplines. Join us to be part of a network dedicated to academic growth and mutual advancement.</div>
                </div>
                <div className='col-md-6 my-4 my-md-0 justify-content-end'>
                    <img src={JoinCommunityImage} className='w-100 w-md-75 rounded' />
                </div>
            </div>
            {/* join our community ends  */}
            <div>
                {/* <div className='text-start fs-5'>Trusted by</div> */}
                <div className='text-start pt-3 pb-3 medium-header'>Our <span className='primary-color'> Alumini Partners </span>works at</div>
                {/* <div className='row d-md-flex justify-content-between mx-0 mt-4 mb-5'>
                    <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-1 px-md-2 py-2 py-md-3'>
                        <div className='px-4'><span className='mx-2'><img src={Google} /></span><span>Google</span></div>
                    </div>
                    <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-1 px-md-2 py-2 py-md-3'>
                        <div className='px-4'><span className='mx-2'><img src={Google} /></span><span>Google</span></div>
                    </div>
                    <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-1 px-md-2 py-2 py-md-3'>
                        <div className='px-4'><span className='mx-2'><img src={Google} /></span><span>Google</span></div>
                    </div>
                    <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-1 px-md-2 py-2 py-md-3'>
                        <div className='px-4'><span className='mx-2'><img src={Google} /></span><span>Google</span></div>
                    </div>
                    <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-1 px-md-2 py-2 py-md-3'>
                        <div className='px-4'><span className='mx-2'><img src={Google} /></span><span>Google</span></div>
                    </div>
                </div> */}
                <div className='row mb-3 mb-sm-5'>
                    <div className='col-md-2 col-2 mt-3'>
                        <img src={tcs} alt="" className='img-fluid' />
                    </div>
                    <div className='col-md-2 col-2 mt-3'>
                        <img src={hcl} alt="" className='img-fluid' />
                    </div>
                    <div className=' col-md-2 col-2 mt-3'>
                        <img src={microsoft} alt="" className='img-fluid' />
                    </div>
                    <div className='col-md-2 col-2 mt-3'>
                        <img src={infoys} alt="" className='img-fluid' />
                    </div>
                    <div className='col-md-2 col-2 mt-3'>
                        <img src={techmahendra} alt="" className='img-fluid' />
                    </div>


                </div>
            </div>
        </div>
    )
}
