import React from 'react'
import { TrendingDomainCards } from '../trending-domain-cards'

export const AllDomains = (props:any) => {
    let category=['Data Science Training','Generative AI','Auto Cad','Machine Learning','IOT & EMBEDDED SYSTEMS','Startup and enterprenuership','VLSI','ROBOTICS USING PYTHON','CYBER SECURITY','CLOUD COMPUTING']

    
 
    return (
        <div className='px-0 px-md-2 mx-md-2 mx-lg-2 mx-xl-2'>
            <div className='d-md-flex justify-content-between mb-5'>
                <div className='text-start'>
                    <div className='medium-header'>All <span className='primary-color'>programs</span></div>
                    {/* <div className='secondary-color'>the most all domains are the ai, data science and react 16</div> */}
                </div>
                {/* <div className='my-4 mt-md-2 text-start me-auto me-md-0'>
                    <select className='form-select border border-light text-white px-5 rounded-0'>
                        {
                            category && category.map((item,index)=>{
                                return (
                                    <option className='primary-bg py-4 px-5 border-bottom' key={index} value={item}>{item}</option>
                                )
                            })
                        }
                     
                    </select>
                </div> */}
            </div>
            <TrendingDomainCards isTrending={true} />
        </div>
    )
}
