import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useNavigate,useLocation } from "react-router-dom";
import { courses } from "../../../config";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const EditTask = () => {
  const navigate = useNavigate();
  
  const location = useLocation();
  const { state } = location;
  
  const [formData, setFormData] = useState<any>({
    batchName: "",
    courseName: "",
    taskName: [], 
    status: "",
  });

  useEffect(()=>{
  if(state){
    setFormData(state)
  }
  },[state])

  console.log("state",state)
  const [errors, setErrors] = useState<any>({});

  const addTask = () => {
    setFormData((prevState: any) => ({
      ...prevState,
      taskName: [...prevState.taskName, ""], // Add an empty string to the array
    }));
  };

  const removeTask = (index: any) => {
    const newTasks = [...formData.taskName]; // Use the correct array field
    newTasks.splice(index, 1);
    setFormData((prevState: any) => ({
      ...prevState,
      taskName: newTasks,
    }));
  };

  const handleInput = (e: any, index?: number) => {
    const { name, value } = e.target;

    if (name !== "taskName") {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (name === "taskName" && typeof index === "number") {
      const newTasks = [...formData.taskName]; // Use the correct array field
      newTasks[index] = value;
      setFormData((prevState: any) => ({
        ...prevState,
        taskName: newTasks,
      }));
    }

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.batchName) {
      newErrors.batchName = "Batch Name is required";
    }

    if (!formData.courseName) {
      newErrors.courseName = "Course Name is required";
    }
    if (!formData.status) {
      newErrors.status = "Status is required";
    }
    

    if (formData.taskName.length === 0 || formData.taskName.includes("")) {
      newErrors.taskName = "All task names must be filled";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validate()) return;

    const payload = {
      batchName: formData.batchName,
      courseName: formData.courseName,
      taskName: formData.taskName,
      status:formData.status
    };

    AdminService.createTask(payload).then((res: any) => {
      if (res.status === "Fail") {
        toast.error(res.message);
      } else {
        toast.success(res.message);
        navigate("/support/tasklist");
      }
    });

    
  };

  const handleCancel = () => {
    navigate("/support/taskList");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Update Tasks</b>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Add Batch Name *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.batchName ? "is-invalid" : ""
                    }`}
                    placeholder="Add Batch Name"
                    name="batchName"
                    onChange={handleInput}
                    value={formData.batchName}
                  />
                  {errors.batchName && (
                    <div className="invalid-feedback">{errors.batchName}</div>
                  )}
                </div>
              </div>
             
              <div className="form-group mt-3">
                <label className="register-font">Select Course *</label>
                <select
                  className={`form-control mt-2 ${
                    errors.courseName ? "is-invalid" : ""
                  }`}
                  onChange={handleInput}
                  name="courseName"
                >
                  <option>{formData?.courseName}</option>
                  {courses.map((ele: any, index) => (
                    <option value={ele.name} key={index}>
                      {ele.name}
                    </option>
                  ))}
                </select>
                {errors.courseName && (
                  <div className="invalid-feedback">{errors.courseName}</div>
                )}
              </div>

              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Status *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.status ? "is-invalid" : ""
                    }`}
                    placeholder="Add Status"
                    name="status"
                    onChange={handleInput}
                    value={formData.status}                    
                  />
                  {errors.status && (
                    <div className="invalid-feedback">{errors.status}</div>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <h5 className="mt-4"><b>Task Name *</b></h5>
                {formData.taskName.map((task: any, index: any) => (
                  <div key={index} className="d-flex mb-3">
                    <input
                      type="text"
                      placeholder="Task Name"
                      name="taskName"
                      className={`form-control mt-2 ${
                        errors.taskName ? "is-invalid" : ""
                      }`}
                      value={task}
                      onChange={(e) => handleInput(e, index)}
                    />
                    <button
                      type="button"
                      className="ms-2 btn btn-danger"
                      onClick={() => removeTask(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addTask}
                >
                  Update
                </button>
                {errors.taskName && (
                  <div className="invalid-feedback d-block mt-2">
                    {errors.taskName}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-3">
              <button type="submit" className="btn btn-primary me-2">
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditTask;
