import React,{useEffect} from 'react'
import { NavBar } from '../components/nav-bar'
import { LearningSkills } from '../components/programs/learning-skills'
import { TrendingDomainCards } from '../components/trending-domain-cards'
import { AllDomains } from '../components/programs/all-domains'
import { Footer } from '../components/footer'
import { FrequentlyAskedQuestions } from '../components/programs/faq'
import { TakOurWord } from '../components/home-page-components/tak-our-word'
import { MasteringReact } from '../components/program-details/mastering-react'
import { AboutProgram } from '../components/program-details/about-program'
import { ProgramCurriculum } from '../components/program-details/program-curriculum'
import { IsProgram } from '../components/program-details/is-program'
import { SampleCertification } from '../components/program-details/sample-certification'
import { GetMoreInfo } from '../components/program-details/get-more-info';
import {useLocation} from 'react-router-dom'
import RelatedDomain from '../components/program-details/RelatedDomain'


export const ProgramDetails = () => {
    const { state } = useLocation();
    useEffect(()=>{
        scrollToTop()
    },[])
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className='container-fluid scroll-bar-width-none primary-bg text-white pt-5' >
            <div className="row">
                <div className="col-md-12">
                    <NavBar activeMenu={"programs"} />
                </div>
                <div className='border-bottom border-white my-3 pb-2 text-center'>
                    <MasteringReact data={state}/>
                </div>
                <div className='border-bottom border-white px-2 px-md-2 px-lg-5 mb-5 pb-5'>
                    <AboutProgram data={state}/>
                </div>
                <div className='border-bottom border-white px-2 px-md-2 px-lg-5 mb-5 pb-5 text-center'>
                    <ProgramCurriculum data={state} />
                </div>
                <div className='border-bottom border-white px-2 px-md-2 px-lg-5 mb-5 pb-5'>
                    <IsProgram />
                </div>
                <div className='border-bottom border-white px-2 px-md-2 px-lg-5 mb-5 pb-5'>
                    <SampleCertification data={state} />
                </div>
                {/* <div className='border-bottom border-white my-5 pb-5'>
                    <TakOurWord />
                </div> */}
                <div className='border-bottom border-white px-2 px-md-2 px-lg-5 mb-3 pb-3'>
                    <div className='fs-1 fw-bold text-start mb-5 px-md-4 '>Related <span className='primary-color'>programs</span></div>
                    {/* <TrendingDomainCards /> */}
                    <RelatedDomain/>
                </div>
                <div className='border-bottom border-white my-3 pb-5'>
                    <GetMoreInfo />
                </div>
                <div className='col-md-12 border-bottom border-light mt-3'>
                    <Footer />
                </div>
            </div>
        </div >
    )
}
