import React from 'react'
import AboutOne from './../../assets/images/about-eduexpose/about-one.png';
import AboutTwo from './../../assets/images/about-eduexpose/about-two.png';
import AboutThree from './../../assets/images/about-eduexpose/about-three.png';
import AboutFour from './../../assets/images/about-eduexpose/about-four.png';
import AboutFive from './../../assets/images/about-eduexpose/about-five.png';
import Star from './../../assets/images/about-eduexpose/star.png';

export const AboutEduExpose = () => {
    return (
        <div className='px-2 mx-md-5'>
            <div className=''>
                <div className='hero-section'>About <span className='primary-color'>Eduexpose</span></div>
                {/* <div className='secondary-color fs-4'>
                    Eduexpose is a best platform to learn the new things like the Data Science, Generative AI and
                    React, these are the best technologies in the world to learn the advanced technologies.
                </div> */}
                <div className='secondary-color fs-4'>Unleash your skills </div>
                <div className='about-images-container my-4'>
                    <div className='row d-flex'>
                        <div className='col-4 '>
                            <div className='ms-auto d-flex justify-content-end'>
                                <img src={AboutOne} className="about-side-image" />
                            </div>
                            <div className='ms-auto d-flex justify-content-end'>
                                <img src={AboutTwo} className="about-side-image" />
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className=''>
                                <img src={AboutThree} className="w-100 about-main-image" />
                            </div>
                        </div>
                        <div className="col-4 me-auto">
                            <div className='ms-auto d-flex justify-content-start'>
                                <img src={AboutFour} alt="" className="about-side-image" />
                            </div>
                            <div className='ms-auto d-flex justify-content-start'>
                                <img src={AboutFive} alt="" className="about-side-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row d-md-flex justify-content-between mx-1 mb-5'>
                <div className='col-5 col-md-4 col-lg-2 border border-white mx-2 my-2 my-md-2 my-lg-0 px-1 px-md-3 py-2'>
                    <div className='fs-1 fw-bold '>60<span className='primary-color'>+</span></div>
                    <div className='secondary-color'><span>Programs Launched</span></div>
                </div>
                <div className='col-5 col-md-4 col-lg-2 border border-white mx-2 my-2 my-md-2 my-lg-0 px-1 px-md-3 py-2'>
                    <div className='fs-1 fw-bold '>100k<span className='custom-primary-color'>+</span></div>
                    <div className='secondary-color'><span>Students registered</span></div>
                </div>
                <div className='col-5 col-md-4 col-lg-2 border border-white mx-2 my-2 my-md-2 my-lg-0 px-1 px-md-3 py-2'>
                    <div className='fs-1 fw-bold '>200<span className='custom-secondary-color'>+</span></div>
                    <div className='secondary-color'><span>Teachers</span></div>
                </div>
                <div className='col-5 col-md-4 col-lg-2 border border-white mx-2 my-2 my-md-2 my-lg-0 px-1 px-md-3 py-2'>
                    <div className='fs-1 fw-bold '>12<span className='custom-primary-color'>+</span></div>
                    <div className='secondary-color'><span>Years of experience</span></div>
                </div>
                <div className='col-5 col-md-4 col-lg-2 border border-white mx-2 my-2 my-md-2 my-lg-0 px-1 px-md-3 py-2'>
                    <div className='fs-1 fw-bold '>4.5<span className='primary-color mx-1 mx-md-0'><img src={Star}/></span></div>
                    <div className='secondary-color'><span>Ratings overall</span></div>
                </div>
            </div>
        </div>
    )
}
