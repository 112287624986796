import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components/tables";
import { LmsVideoDataGridCols } from "./LmsVideoDataGridCols";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const LmsVideoList = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  let usersData: any = [];
  const[lmsData,setLmsData]=useState([]);

  const handleCancel = () => {
    setName("");
  };
  const handleSearch = () => {};
  const handleLmsCreateMeet = () => {
    navigate("/support/createlmscourses");
  };
  useEffect(() => {
    getLms();
  }, []);

  const getLms = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getallLmslist(data).then((res: any) => {
      if (res.status === "Success") {
        setLmsData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };
  const deleteCourseInfo = (lmsId: string) => {
    const payload = {
      lmsId: lmsId,
    };
    AdminService.deleteLms(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getLms();
      } else {
        toast.error(res.message);
      }
    });
  };
  const handleEditUser = (data: any) => {
    navigate("/support/editlms", { state: data });
  };
  return (
    <div>
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <div className="row">
            <div className="col-md-6 mt-3">
              <label className="form-label d-flex justify-content-start text-start">
                <b>Lms List</b>
              </label>
              <div className="d-flex">
                <input
                  name="User Name"
                  onChange={(e: any) => setName(e.target.value)}
                  className="form-control mt-1 me-2"
                  value={name}
                  placeholder="Enter the Title"
                />
                <button
                  className="btn btn-primary rounded px-4 py-1"
                  onClick={() => handleSearch()}
                >
                  Search
                </button>
                <button
                  className="btn btn-secondary rounded ms-2 px-4 py-1"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="text-end">
                <button
                  className="btn btn-success"
                  onClick={handleLmsCreateMeet}
                >
                  Create LMS Videos
                </button>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <DataTable tableData={lmsData} TableCols={LmsVideoDataGridCols} deleteCourseInfo={deleteCourseInfo} handleEditUser={handleEditUser}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default LmsVideoList;
