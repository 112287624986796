import React from "react";
import { NavBar } from "../components/nav-bar";
import { HomePageNavigation } from "../routes/homepage-routes";
import { AcquireSkills } from "../components/home-page-components/acquire-skills";
import { TrendingDomains } from "../components/home-page-components/trending-domains";
import { InternshipRoadMap } from "../components/home-page-components/Internship-roadmap";
import { EduexposeDifference } from "../components/home-page-components/eduexpose-difference";
import { TakOurWord } from "../components/home-page-components/tak-our-word";
import { Footer } from "../components/footer";
import { JoinCommunity } from "../components/home-page-components/join-community";
import TakeOurSectionRightImage from "./../assets/images/take-our-section-right-image.jpg";
import GifVideos from "../assets/images/videos/EduexposeGIF.gif";
import GoogleImgae from "./../assets/images/google.png";
import Recognized from "../components/home-page-components/recognized";
import infoys from "./../assets/images/reviews/Infosys.jpeg";
import ibm from "./../assets/images/reviews/ibm.png";
import byjus from "./../assets/images/reviews/byjus.jpeg";

export const Home = () => {
  const testimonials = [
    {
      text: "My internship at EDUEXPOSE Company was a game-changer. From impactful projects to cutting-edge tech exposure, it was a dynamic learning journey. The supportive team and inclusive culture made me feel valued from day one.",
      name: "Vikash Kaladi",
      company: "infosys",
      image: TakeOurSectionRightImage, // Make sure to import your image
      companyImage:infoys // Make sure to import your image
    },
    {
      text: "I got an internship opportunity at eduexpose and i learned ai from sratch level to advanced level ,the mentors are very supportive i had got the certifications for the same i highly recommend my mates to go for the same",
      name: "Sai Jayanth",
      company: "Google",
      image: TakeOurSectionRightImage, // Make sure to import your image
      companyImage:GoogleImgae
    },
    {
      text: "I had a great experience while doing the online internship , i got industrial exposure and learned basics of python , i totally loved this internship",
      name: "Krithika Srivastav",
      company: "Byjus",
      image: TakeOurSectionRightImage, // Make sure to import your image
      companyImage:byjus
    },
    {
      text: "being an mechanical student learning cs domains is tough part , but eduexpose proved me wrong ,i learned python in 45 days training period proud to be an intern at eduexpose",
      name: "Prasad",
      company: "Ibm",
      image: TakeOurSectionRightImage, // Make sure to import your image
      companyImage:ibm
    },
  ];
  return (
    <div className="container-fluid scroll-bar-width-none primary-bg  pt-5">
      <div className="row">
        <div className="col-md-12 ">
          <NavBar activeMenu={"home"} />
        </div>
        <div className="col-md-12 border-bottom mt-3 py-4 my-md-5 py-md-4 text-center text-white">
          <AcquireSkills />
        </div>
        <div className="col-md-12 border-bottom text-center text-white">
          <Recognized />
          {/* </div> */}
          {/* <div className='col-md-12 border-bottom border-lightmt-3 py-3 my-md-3 py-md-3'> */}
          <TrendingDomains />
        </div>
        <div className="col-md-12 border-bottom border-lightmt-3 py-3 my-md-3 py-md-3 text-white">
          <JoinCommunity />
        </div>
        <div className="col-md-12 border-bottom border-lightmt-3 py-4 my-md-3 py-md-4 text-white">
          <InternshipRoadMap />
        </div>
        <div className="col-md-12 border-bottom border-lightmt-3 my-3 py-4 text-white">
          <EduexposeDifference />
        </div>
        <div className="col-md-12 border-bottom border-lightmt-3 py-4 my-md-3 py-md-4 text-white">
          <TakOurWord testimonials={testimonials} />
        </div>
        <div className="col-md-12 border-bottom border-light mt-3 text-white">
          <Footer />
        </div>
      </div>
    </div>
  );
};
