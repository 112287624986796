import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const UserCalendar = () => {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getMeets();
  }, []);

  const getMeets = async () => {
    try {
      // Sample data to send in the API request
      const data = {
        search: "",
        start: 1,
        count: 10,
      };
  
      // Fetch user data from sessionStorage
      const userdata:any = sessionStorage.getItem("loginData");
      const userRole = JSON.parse(userdata);
      const userCourses = userRole.courseName; // Assuming courseName is an array of strings
  
      // Fetch meeting data
      const res = await AdminService.getAllMeets(data);  
      if (res.status === "Success") {
        // Filter the fetched meets data by userCourses
        const filteredData = res.data.filter((meet: any) =>
          userCourses.includes(meet.courseName)
        );
  
        // Format the filtered data for calendar or UI display
        const formattedEvents = filteredData.map((meet: any) => {
          const [datePart, startPart, , endPart] = meet.meetDate.split(" ");
          const startDate = `${datePart} ${startPart}`;
          const endDate = `${datePart} ${endPart}`;
  
          const start = moment(startDate, "YYYY-MM-DD hh:mm A").toDate();
          const end = moment(endDate, "YYYY-MM-DD hh:mm A").toDate();
  
          return {
            id: meet._id,
            title: meet.title,
            start,
            end,
            link: meet.link, // Include the link in the event data
          };
        });
  
        // Set the formatted events to the state
        setEvents(formattedEvents);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error("Error fetching meets:", error);
      toast.error("Failed to fetch meets.");
    }
  };

  const handleSelectEvent = (event:any) => {
    window.open(event.link, "_blank"); // Open the link in a new tab
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-5 py-5">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              onSelectEvent={handleSelectEvent} // Add the event handler
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCalendar;
