import React, { useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { UserService } from "../../../app/service/user.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const ForPassword = () => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<any>({});
  const navigate=useNavigate();

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.oldPassword) {
      newErrors.oldPassword = "Old Password is required";
    }

    if (!formData.newPassword) {
      newErrors.newPassword = "New Password is required";
    } else if (formData.newPassword.length < 6) {
      newErrors.newPassword = "New Password must be at least 6 characters long";
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validate()) return;
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    const data = {
      userId: userRole?.id,
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    };
    UserService.changePassword(data)
      .then((res: any) => {
        if (res.status === "Success") {
          toast.success(res.message)
          navigate("/sign-in");
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
      });
  };

  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-5 py-5">
            <h2 className="text-center">Change Password</h2>
            <form>
              <div className="mb-3">
                <label htmlFor="oldPassword" className="form-label">
                  Old Password
                </label>
                <input
                  type="password"
                  id="oldPassword"
                  className={`form-control mt-2 ${
                    errors.oldPassword ? "is-invalid" : ""
                  }`}
                  placeholder="Enter Old Password"
                  name="oldPassword"
                  value={formData.oldPassword}
                  onChange={handleInput}
                  required
                />
                {errors.oldPassword && (
                  <div className="invalid-feedback">{errors.oldPassword}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="newPassword" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  id="newPassword"
                  className={`form-control mt-2 ${
                    errors.newPassword ? "is-invalid" : ""
                  }`}
                  placeholder="Enter New Password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleInput}
                  required
                />
                {errors.newPassword && (
                  <div className="invalid-feedback">{errors.newPassword}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  className={`form-control mt-2 ${
                    errors.confirmPassword ? "is-invalid" : ""
                  }`}
                  placeholder="Confirm New Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInput}
                  required
                />
                {errors.confirmPassword && (
                  <div className="invalid-feedback">
                    {errors.confirmPassword}
                  </div>
                )}
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-25"
                  onClick={handleSubmit}
                >
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForPassword;
