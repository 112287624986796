import React, { useEffect } from "react";
import { NavBar } from "../nav-bar";

const Terms = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="  pt-5">
      <div className="row">
        <div className="col-md-12 ">
          <NavBar />
        </div>
      </div>
      <div className="container">
        <div className="col-md-12  mt-3 py-4 my-md-5 py-md-4 text-start">
          <h1>Terms & Conditions</h1>
          <p className="mt-3">
            This document is published in accordance with the provisions of Rule
            3 (1) of the Information Technology (Intermediaries Guidelines)
            Rules, 2011 that require publishing the rules and regulations,
            Privacy Policy and Terms of Use for access or usage of the
            www.edvedha.com
          </p>
          <p className="mt-5">
            EDUEXPOSE("EDUEXPOSE," "us," "our" or "we") provides this website to
            you ("User") under the following terms and conditions (this
            "Agreement" or "Terms of Use"). Our Privacy Policy (as in posted to
            our website), which explains how we collect and use information from
            visitors to our website is incorporated by reference in these Terms
            of Use. By using our website (the "Site") or submitting any
            information to us, you consent to and agree to be bound by these
            Terms of Use and our Privacy Policy. By using the Site in any
            manner, including but not limited to visiting or browsing the Site,
            you agree to be bound by this Agreement, the Privacy Policy, and all
            other operating rules, policies and procedures that may be published
            by us from time to time on the Site, each of which is incorporated
            by reference and each of which may be updated by us from time to
            time without notice to you. Your use of the Site is governed by the
            version of this Agreement in effect on the date of use.
          </p>
          <h4 className="mt-5">Account Registration</h4>
          <p className="mt-4">
            If you are under the age of 18, please do not sign up for an
            account. If you are accessing or using our site on behalf of any
            entity, you represent that you are authorized to accept these Terms
            of Use on that entity's behalf, that entity agrees to be responsible
            to EDUEXPOSEif you violate these Terms of Use.
          </p>
          <p className="mt-4">
            You may access some areas of our site(s) without registering.
            However, in order to access certain features of the Site or to post
            content on the Site you must register to create an account
            ("Account"). You must complete the registration process by providing
            us with current, complete and accurate information. If you choose to
            register you will need to provide a username and password as well as
            other information that is part of our registration process. You will
            not share your password, let anyone else access your Account, or do
            anything else that might jeopardize the security of your Account. We
            have the right to cancel any account at any time if we believe you
            are not in compliance with any or all of the terms.
          </p>
          <h4 className="mt-5">Courses & Offered Payment</h4>
          <p className="mt-4">
            Information about courses offered and prices would be provided in
            the Website. You agree to pay the price for the courses registered
            as per the pricing schedule indicated in the Site. At the time of
            apayment, you need to provide accurate information regarding your
            account, including a valid credit card or debit card or net banking
            credentials, and you must certify that you are over 18 years of age
            and legally able to enter into a valid and binding agreement with
            Ed-Vedha.
          </p>
          <h4 className="mt-5">Processing & Account Payment</h4>
          <p className="mt-4">
            EDUEXPOSEmay use third party electronic payment processors and/or
            financial institutions ("EPPs") to process financial transactions.
            You irrevocably authorize us, as necessary, to instruct such EPPs to
            handle such transaction and you irrevocably agree that EDUEXPOSEmay
            give such instructions on your behalf in accordance with your
            requests as submitted on the Site. While availing any of the EPPs
            available on the Site, We will not be responsible or assume any
            liability, whatsoever in respect of any loss or damage arising
            directly or indirectly to you due to:
            <br />
            Lack of authorization for any transactions.
            <br />
            Any payment issues arising out of the transaction or Decline of such
            transaction for any reason.
            <br />
            You understand, accept and agree that the payment facility provided
            us, is neither a banking nor financial service.
          </p>
          <h4 className="mt-5">Modification of Pricing And Billing Terms</h4>
          <p className="mt-4">
            We reserve the right at any time to institute new prices, and to
            change the amount of or the basis for determining any prices or
            charges with respect to the any courses offered. You agree to pay
            all charges, including applicable taxes, in accordance with the
            billing terms in effect at the time the price or charge becomes
            payable.
          </p>
          <h4 className="mt-5">INTELLECTUAL PROPERTY</h4>
          <p className="mt-4">
            EDUEXPOSEexpressly reserves all intellectual property rights in all
            text, programs, products, processes, technology, content, software
            and other materials, which appear on this Website. The compilation
            (meaning the collection, arrangement and assembly) of the content on
            the Website is the exclusive property of EDUEXPOSE. Reproduction in
            whole or in part of the same is strictly prohibited without the
            express written permission of Ed-Vedha.
          </p>
          <p className="mt-2">
            The trademarks, logo and slogans displayed on the Site including the
            mark EDUEXPOSE, (collectively the "Trademarks") are owned by
            EDUEXPOSE. Nothing on this Site should be construed as granting any
            license or right to use the Trademarks without written permission of
            EDUEXPOSE or such third party owner of the Trademarks. Your misuse
            of the Trademarks, or any other content on the Site, except as
            provided in these Terms of Use, is strictly prohibited. You are
            advised that EDUEXPOSE will aggressively enforce its intellectual
            property rights to the fullest extent of the law.
          </p>
          <p className="mt-2">
            EDUEXPOSE grants to the registered users a non-exclusive,
            non-transferable, revocable right to use the contents on the Site
            for non-profit, educational and research use only, without the right
            of sublicense. EDUEXPOSE respect the intellectual property of
            others, and we ask our Users and content partners to do the same.
            The unauthorized posting, reproduction, copying, distribution,
            modification, public display or public performance of copyrighted
            works constitutes infringement of the copyright owner’s rights. As a
            condition of your use of the Site, you agree not to use any feature
            of the Site to infringe the intellectual property rights of others
            in any way. We will terminate the accounts of any User, and block
            access to Site of any User who are repeat infringers of the
            copyrights, or other intellectual property rights, of EDUEXPOSE or
            others. We reserve the right to take these actions at any time, in
            our sole discretion, with or without notice, and without any
            liability to the User who is terminated or to the User whose access
            is blocked.
          </p>
          <p className="mt-2">
            Intellectual Property Right” for the purpose of the Terms of Use,
            shall mean all copyrights (including without limitation the
            exclusive right to reproduce, distribute copies of, display and
            perform the copyrighted work and to prepare derivative works),
            copyright registrations and applications, trademark rights
            (including, without limitation, registrations and applications),
            patent rights (whether registered or unregistered), trade names or
            business names, registered and unregistered design rights, mask-work
            rights, trade secrets, moral rights, author’s rights, rights in
            packaging, goodwill and other intellectual property rights, and all
            renewals and extension thereof and all rights or forms of protection
            of a similar nature or having an equivalent or similar effect to any
            of the above, which may subsist anywhere in the world, regardless of
            whether any of such rights arise under the laws of India or any
            other state, country or jurisdiction.
          </p>
          <h4 className="mt-3">DATA PROTECTION/PRIVACY</h4>
          <p className="mt-3">
            We may change this Policy at any time, in particular as needed to
            comply with the rules and regulations of the various governmental
            and regulatory organizations, or to comply with applicable laws and
            regulations. The revised version will be effective at the time we
            post it on website or inform to the User. If a User uses the
            Services or accesses the website after a notice of changes has been
            sent to such User or published on the Website, such User hereby
            provides his/her/its consent to the changed terms.
          </p>
          <h4 className="mt-3">RESTRICTED ACTIONS</h4>
          <p className="mt-3">During the use of the Site, you shall not:</p>
          <p className="mt-2">
            Attempt to copy, modify, duplicate, imitate, reproduce, create
            derivative works from, frame, mirror, or download, all or any
            portion of the Site in any form or media or by any means.{" "}
          </p>
          <p className="mt-2">
            Attempt to decompile, disassemble, reverse engineer or otherwise
            attempt to discover any source code from, all or any part of the
            site.
          </p>
          <p className="mt-2">
            Sell, resell, redistribute or otherwise commercially exploit any
            material from this Site except for content specifically and
            expressly made available for redistribution.
          </p>
          <p className="mt-2">
            Access or use all or any part of the Site in order to build or
            create a product or service which is similar to, or which competes
            with, the business of Eduexpose.
          </p>
          <p className="mt-2">
            Attempt to obtain, or assist third parties in obtaining,
            unauthorized access to the site.
          </p>
          <p className="mt-2">
            Abuse the use of site in any way including for advertising or
            solicitation to buy or sell any products and/or for creating,
            sharing and sending unsolicited commercial messages, bulk email,
            "junk mail", "spam" or chain letters.
          </p>
          <p className="mt-2">
            Create or send any viruses, worms or trojan horses, flood or mail
            bombs, or engaging in denial of service attacks while using the
            Site.
          </p>
          <p className="mt-2">
            Use the Site in any manner that damages, disables, overburdens, or
            impairs the Site, or EDUEXPOSE's systems or servers, or the cloud or
            other platform on which the we operate, and/or otherwise interferes
            with any other party's use and enjoyment of the Site.
          </p>
          <p className="mt-2">
            Access the site by any means other than through the interface that
            is provided by us for accessing the Site.
          </p>
          <p className="mt-2">
            Permit any third party to do any of the foregoing.
          </p>
          <p className="mt-2">
            You shall use all reasonable endeavours to prevent any unauthorised
            access to, or use of, the Site and, in the event of any such
            unauthorised access or use, it shall promptly notify us.
          </p>
          <h4 className="mt-4">THIRD PARTY CONTENT</h4>
          <p className="mt-3">
            The Site may provide links to or frame third-party websites or
            resources and may link User automatically to sponsors’ or third
            party’s websites or resources. You acknowledge and agree that
            EDUEXPOSE is not responsible or liable for: (i) the availability or
            accuracy of such websites or resources; or (ii) the content,
            products, or services on or available from such websites or
            resources. Links to such websites or resources do not imply any
            endorsement by EDUEXPOSE of such websites or resources or the
            content, products, or services available from such websites or
            resources. You acknowledge sole responsibility for and assume all
            risk arising from your use of any such websites or resources.You
            should review the applicable third party’s terms and policies,
            including privacy and data gathering practices of any website to
            which you navigate.
          </p>
          <h4 className="mt-3">NOTIFICATIONS</h4>
          <p className="mt-3">
            You agree to EDUEXPOSE sending you notifications and important
            messages time to time via its websites, mobile applications, and
            email to provide you a better experience with the services provided
            by us. You agree that we may provide notices to you on our website,
            or through an email sent to an email address you provided, or
            through other means including but not limited to your mobile number,
            telephone, or email. You agree to keep your contact information up
            to date.
          </p>
          <h4 className="mt-3">GRIEVANCE OFFICER</h4>
          <p className="mt-3">
            In accordance with Information Technology Act 2000 and rules made
            there under, the contact details of the Grievance Officer are
            provided below:
          </p>
          <p className="mt-3">Phone: 71077 06919</p>
          <p className="mt-3">Email: Support@eduexpose.in</p>
          <p className="mt-3">Time: 11:00 AM - 09:00 PM</p>
          <h4 className="mt-3">NO REPRESENTATIONS OR WARRANTIES</h4>
          <p className="mt-3">
            Your access to the site is at your sole risk. Our services are
            provided on “AS IS” and “AS AVAILABLE” basis without warranties of
            any kind, either express or implied, including, but not limited to,
            implied warranties of merchantability, fitness for a particular
            purpose, title, and non-infringement. EDUEXPOSE makes no
            representation as to the completeness or accuracy of the information
            provided on the Site. EDUEXPOSE does not endorse or represent the
            reliability or accuracy of any content or information distributed
            through or accessed from the Site, and has not performed any
            investigation into such information.
            <br />
            <br />
            To the extent provided by law, EDUEXPOSE assumes no liability or
            responsibility for any errors or omissions in the content of the
            Site. EDUEXPOSE has not reviewed all of the links provided on the
            Site and is not responsible for the content of any off-Site pages.
            Clicking on hyperlinks and visiting any off-Site pages is done at
            your own risk.
          </p>
          <h4 className="mt-4">CHANGE OR TERMINATION</h4>
          <p className="mt-3">
            We may, without prior notice, change the Site, stop providing the
            Site, applications or services, or create usage limits for the Site.
            We may permanently or temporarily terminate or suspend your access
            to the Site without notice or liability, for any reason or for no
            reason, including if in our sole determination you violate any
            provision of these Terms of Use. Upon termination of these Terms of
            Use or your access to the Site for any reason or no reason, you will
            continue to be bound by these Terms of Use which, by their nature,
            should survive termination, including without limitation ownership
            provisions, warranty disclaimers, indemnity, and limitations of
            liability.
          </p>
          <h4 className="mt-4">LIMITATION OF LIABILITY</h4>
          <p className="mt-3">
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER
            EDUEXPOSENOR ITS AFFILIATES WILL BE LIABLE UNDER ANY THEORY OF
            LIABILITY (WHETHER IN CONTRACT, TORT, NEGLIGENCE, OR OTHERWISE) FOR
            ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR
            EXEMPLARY DAMAGES, INCLUDING, DAMAGES FOR LOSS OF REVENUES, PROFITS,
            GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH
            PARTIES WERE ADVISED OF, KNEW OF, OR SHOULD HAVE KNOWN OF THE
            POSSIBILITY OF SUCH DAMAGES), ARISING FROM OR RELATING TO THE SITES
            OR SERVICES OR THESE TERMS OF USE.
          </p>
          <h4 className="mt-3">INDEMNIFICATION</h4>
          <p className="mt-3">
            User agrees to indemnify, defend and hold harmless EDUEXPOSE and its
            Affiliates from and against any and all claims, liabilities,
            damages, losses, costs, expenses, and fees of any kind (including
            reasonable attorneys’ fees and legal costs), arising from or
            relating to: (a) any information (including User Content or any
            other content) that User or anyone using User’s account submits,
            posts, or transmits on or through the Sites or Services; (b) the use
            of the Sites or Services by User or anyone using User’s account; (c)
            the violation of these Terms of Use by User or anyone using User’s
            account; or (d) the violation of any rights of any third party,
            including intellectual property, privacy, publicity, or other
            proprietary rights by User or anyone using User’s account.
          </p>
          <h4 className="mt-3">JURISDICTION</h4>
          <p className="mt-3">
            The Terms of Use shall be governed by the laws of India, and the
            courts at Bengaluru, India shall have exclusive jurisdiction with
            respect to any dispute arising hereunder.
          </p>
          <h4 className="mt-3">INTERNATIONAL USE</h4>
          <p className="mt-3">
            The Site is controlled and operated from within the India. EDUEXPOSE
            makes no representation that the Site is appropriate or available in
            locations outside of India. Those who choose to access the Site or
            use our services from other locations do so at their own risk and
            are responsible for compliance with applicable laws.
          </p>
          <h4 className="mt-3">GENERAL</h4>
          <p className="mt-3">
            The failure of EDUEXPOSE to enforce any right or provision of these
            Terms of Use will not constitute a waiver of future enforcement of
            that right or provision. The waiver of any such right or provision
            will be effective only if in writing and signed by a duly authorized
            representative of EDUEXPOSE. Except as expressly set forth in these
            Terms of Use, the exercise by either party of any of its remedies
            under these Terms of Use will be without prejudice to its other
            remedies under these Terms of Use or otherwise. If for any reason a
            court of competent jurisdiction, as applicable, of competent
            jurisdiction finds any provision of these Terms of Use invalid or
            unenforceable, that provision will be enforced to the maximum extent
            permissible and the other provisions of these Terms of Use will
            remain in full force and effect.
          </p>
          <h4 className="mt-4">MISCELLANEOUS</h4>
          <p className="mt-3">
            Except as expressly provided in these Terms of Use, there shall be
            no third-party beneficiaries to the Terms of Use. EDUEXPOSE shall
            have the right to assign its rights or delegate any of its
            responsibilities under these Terms of Use to an affiliate or in
            connection with a merger, consolidation or reorganization of
            EDUEXPOSE for the sale of substantially all of its assets.
            <br />
            <br />
            In the event that any provision of this Agreement, or the
            application thereof, becomes or is declared by a court of competent
            jurisdiction to be illegal, void or unenforceable, the remainder of
            this Agreement will continue in full force and effect and the
            application of such provision to other persons or circumstances will
            be interpreted so as reasonably to effect the intent of the parties
            hereto.
            <br />
            <br />
            This Agreement constitute the entire agreement between EDUEXPOSE and
            User regarding the subject matter hereof, and supersede any previous
            arrangement, understanding or agreement between the parties, written
            or oral, relating to the subject matter hereof.
            <br />
            <br />
            Notwithstanding anything to the contrary herein, EDUEXPOSE will not
            be held liable for any delay or failure in performance or
            non-availability of the services resulting, directly or indirectly,
            from acts of nature, events, omissions, accidents or causes beyond
            its reasonable control, including, but not limited to, internet
            failure, network or computer equipment failures, telecommunication
            equipment failure, electrical power failures, strikes, lock-outs or
            other industrial disputes, labour disputes, riots, insurrections,
            civil disturbances, shortages of labour or materials, fires, floods,
            storms, explosions, breakdown of plant or machinery, actsof God,
            war, governmental actions or orders, orders of domestic or foreign
            courts or tribunals.
          </p>
          <h4 className="mt-4">ONLINE INTERNSHIP</h4>
          <h5 className="mt-2">SCOPE</h5>
          <p className="mt-3">
            "The fees that we charge the students is only for training them via
            live classes and not internship itself. EDUEXPOSE does not charge
            any amount for the internship project phase as it is completely
            complimentary."
          </p>
          <h4 className="mt-4">OFFLINE INTERNSHIP</h4>
          <h5 className="mt-2">SCOPE</h5>
          <p className="mt-3">
            "The fees that we charge the students is for the offline workshops
            that are conducted and not for the online project phase. The online
            project phase would be complimentary for all the participants in
            offline work shop."
          </p>
          <h4 className="mt-4">PURPOSE</h4>

          <p className="mt-3">
            This policy governs the refunds process and will address matters
            arising out of situations that may require processing of refunds.
          </p>
          <h5 className="mt-2">SCOPE</h5>
          <p className="mt-3">
            The policy encompasses employees, students, and candidates that are
            in the ecosystem of requesting and/or processing refunds.
            <br />
            This policy does not cover situations where refunds are not
            approved.
            <br />
            There are two components to internship, certification and job
            guarantee program fees:
            <br />
            Registration Charges.
            <br />
            Course Fees.
          </p>
          <h4 className="mt-3">PROCEDURE</h4>
          <h5 className="mt-3">Programs:</h5>
          <h5 className="mt-3">Internship Programs</h5>
          <h5 className="mt-3">Self Paced Internship Program:</h5>
          <p className="mt-3">
            All self paced internship programs are non-refundable and
            non-transferrable under any circumstances. Full refunds will be
            issued only if EDUEXPOSEcancels the complete program.
          </p>
          <h4 className="mt-3">Online Internship Program</h4>
          <p className="mt-3">
            All online internship programs are non-refundable and
            non-transferrable under any circumstances. Full refunds will be
            issued only if EDUEXPOSEcancels the complete program.
          </p>
          <h4 className="mt-3">Offline Internship Program</h4>
          <h5 className="mt-3">Hybrid Internship Program</h5>
          <p className="mt-3">
            All offline and Hybrid internship programs are non-refundable and
            non-transferrable under any circumstances. Full refunds will be
            issued only if EDUEXPOSEcancels the complete program.
          </p>
          <h4 className="mt-3">Certification Programs</h4>
          <h5 className="mt-3">Self Paced Certification Programs:</h5>
          <p className="mt-3">
            Self Paced certification programs are refundable only if refund
            request is raised up to 1 week before the date of commencement and
            the student has not accessed the LMS. Any refund requests post LMS
            access will be strictly denied. Please note that the registration
            charges which is 20% of the course fee or Rs.1000/- (whichever is
            higher) are non refundable at any point in time.
          </p>
          <h4 className="mt-3">Online Certification Program:</h4>
          <p className="mt-3">
            All online certification programs are non-refundable and
            non-transferrable under any circumstances. Full refunds will be
            issued only if EDUEXPOSE cancels the entire program.
          </p>
          <h4 className="mt-3">Offline Certification Program:</h4>
          <p className="mt-3">
            Offline certification programs are refundable only if refund request
            is raised 1 week prior to the commencement of classes and the
            student has not accessed the LMS. Please note that the registration
            charges which is 20% of the course fee or Rs.1000/- (whichever is
            higher) are non refundable at any point in time.
          </p>
          <h4 className="mt-3">Hybrid Certification Program:</h4>
          <p className="mt-3">
            Hybrid certification programs are non-refundable and
            non-transferrable under any circumstances. Full refunds will be
            issued only if EDUEXPOSE cancels the entire program.
          </p>
          <h4 className="mt-3">Job Guarantee Programs</h4>
          <h5 className="mt-3">
            Self Paced Job Guarantee (Assistance) Program:
          </h5>
          <p className="mt-3">
            Self paced programs are refundable only if refund request is raised
            up to 1 week before the date of commencement and the student has not
            accessed the LMS. Any refund requests postLMS access will be
            strictly denied. Please note that the registration charges which is
            20% of the course fee or Rs.1000/- (whichever is higher) are non
            refundable at any point in time.
          </p>
          <h5 className="mt-3">Online Guarantee Program:</h5>
          <p className="mt-3">
            All online job guarantee programs are non-refundable and
            non-transferrable under any circumstances. The refunds will happen
            only if student has successfully completed the course but is unable
            to secure a job provided: 85% attendance for the classes held.
          </p>
          <p className="mt-3">
            All major projects, minor projects and case studies have been
            successfully completed.
          </p>
          <p className="mt-3">
            Exams or certification requirement for the course has been met.
          </p>
          <p className="mt-3">
            Full refunds will be issued only if EDUEXPOSE cancels the entire
            program.
          </p>
          <h4 className="mt-3">OFFLINE CERTIFICATION PROGRAM:</h4>
          <p className="mt-3">
            Offline certification programs are refundable only if refund request
            is raised 1 week prior to the commencement of classes and the
            student has not accessed the LMS. Please note that the registration
            charges which is 20% of the course fee or Rs.1000/- (whichever is
            higher) are non refundable at any point in time.
          </p>
          <h5 className="mt-3">Hybrid Certification Program:</h5>
          <p className="mt-3">
            Hybrid certification programs are non-refundable and
            non-transferrable under any circumstances. Full refunds will be
            issued only if EDUEXPOSE cancels the entire program.
          </p>
          <h5 className="mt-3">Process for Refund:</h5>
          <p className="mt-3">
            For all refunds please contact us at: support@EDUEXPOSE.in The
            refunds team will contact you within 2-5 business days. For approved
            refund cases Refunds Form need to be filled. In 7-10 business days
            refund is issued.
          </p>
          <h5 className="mt-3">How Your Refund is Returned:</h5>
          <p className="mt-3">
            If your payment came from your card, it will be refunded to that
            card. Depending on your card issuer, it may take up to 30 days to
            complete a refund.
          </p>
          <p className="mt-3">
            If your payment came from your bank account, it will be refunded to
            that bank account.
          </p>
          <p className="mt-3">
            If your payment came from some other source (instamojo, phonepe,
            googlepay etc) it will be refunded back to the same source.
          </p>
          <p className="mt-3">
            Please note no cash refunds are made under any circumstances.
          </p>
          <h4 className="mt-3">CHANGE OF COURSE</h4>
          <p className="mt-3">
            EDUEXPOSE allows you to change your course to an alternative course
            only once using the following guidelines:
          </p>
          <p className="mt-3">
            EDUEXPOSE is notified in writing at least 1 week before the
            commencement of the original course. The alternative course has
            seats available and will be finished within 1 month of the original
            course finish date. If switching to course of a higher value,
            students are required to pay the amount difference. However, if
            switching to a course of a lower value, EDUEXPOSE is not liable to
            pay the amount difference. A processing fee of Rs. 500/- needs to be
            paid within 3 days after EDUEXPOSE accepts change of course request,
            failing which course change request will be cancelled and no further
            request will be entertained.
            <br />
            <br />
            Course change is allowed only once during the program.
          </p>
          <h4 className="mt-3">PROCESS FOR CHANGE OF COURSE</h4>
          <p className="mt-3">
            Contact EDUEXPOSE at support@EDUEXPOSE.com with your course change
            request.
          </p>
          <p className="mt-3">
            Once approved, fill up the “Course Change Form” and submit it within
            3 business days.
          </p>
          <p className="mt-3">
            Pay the processing fees + difference in course costs (if applicable)
            and submit the receipt to “ support@EdVedha.com ”.
          </p>
          <p className="mt-3">
            In 5-7 business days, you will get a confirmation for the course
            change request.
          </p>
          <h4 className="mt-3">RESCHEDULE</h4>
          <p className="mt-3">
            Rescheduling by EDUEXPOSE are governed by the following guidelines:
            <br />
            <br />
            If a course doesn’t begin within 10 business days of published
            commencement date, a student is entitled to either a full refund or
            can choose to join a different batch/course.
            <br />
            <br />
            EDUEXPOSE will make all possible efforts to conduct a course.
            However, in the event of a course being rescheduled due to
            unforeseen delays on our end, EDUEXPOSE is only responsible for the
            cost of the course. Any travel, logistics or personal expenses
            incurred due to this program won’t be refunded.
          </p>
          <h5 className="mt-3">
            Rescheduling request by customer are governed by the following
            guidelines:
          </h5>
          <p className="mt-3">
            The reschedule request has to be made at least 7 business days
            before the commencement of the course.
          </p>
          <p className="mt-3">
            The rescheduled date should be within 90 days of the initial
            commencement date.
          </p>
          <p className="mt-3">
            Rescheduling will not allow the customer to change their course.
          </p>
          <p className="mt-3">
            The reschedule is subject to availability of seats.
          </p>
          <p className="mt-3">
            A rescheduling fee of Rs. 500/- must be paid within 5 business days
            from the date of approval.
          </p>
          <h4 className="mt-3">Process for Rescheduling of Course:</h4>
          <p className="mt-3">
            Contact EDUEXPOSE at support@eduexpose.in with your course
            rescheduling request.
          </p>
          <p className="mt-3">
            Once approved, fill up the “Reschedule Course Form” and submit it
            within 3 business days.
          </p>
          <p className="mt-3">
            Pay the processing fees + difference in course costs (if applicable)
            and submit the receipt to “ support@eduexpose.in ”In 5-7 business
            days you will get a confirmation for the course reschedule request.
          </p>
          <p className="mt-3">
            If rescheduled course is of a higher value, students are required to
            pay the amount difference. However, if switching to a course of a
            lower value, EDUEXPOSE is not liable to pay the amount difference. A
            processing fee of Rs. 500/- needs to be paid within 3 days after
            EDUEXPOSE accepts reschedule request, failing which reschedule
            request will be cancelled and no further request will be
            entertained.
          </p>
          <h4 className="mt-3">CANCELLATION</h4>
          <p className="mt-3">
            EDUEXPOSE reserves the right to cancel any session or change the
            location of the session for reasons such as Instructor
            unavailability, insufficient participants or force majeure events
            such as earthquakes, fire, flood, riots, curfew, bandh, protest,
            strike, civil unrest etc. Please note our cancellation policy is
            governed by the following:
          </p>
          <p className="mt-3">
            In case EDUEXPOSE cancels a workshop/seminar/conference, 100% refund
            will be paid to the participants.
          </p>
          <p className="mt-3">
            In case EDUEXPOSE changes the location of the event, arrangements of
            a similar infrastructure as previously decided will be executed.
          </p>
          <p className="mt-3">
            No personal expenses due to the change of location will be borne by
            EDUEXPOSE under any circumstances. EDUEXPOSE reserves the right to
            either accommodate students in a similar infrastructure as
            originally decided or pay compensation EDUEXPOSE deems fit for the
            same.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
