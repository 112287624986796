import API from "../utility/axios";
export class UserService {
    static addRegister(data: any): Promise<any> {
        return API.post(`/createRegister`, data);
    }
    static userLogin(data: any): Promise<any> {
        return API.post(`/login`, data);
    }
    static userInfo(data: any): Promise<any> {
        return API.post(`/userInfo`, data);
    }
    static createQueries(data: any): Promise<any> {
        return API.post(`/createQueries`, data);
    }
    static certificatesList(data: any): Promise<any> {
        return API.post(`/certificatesList`, data);
    }
    static loasList(data: any): Promise<any> {
        return API.post(`/loasList`, data);
    }
    
    static attendancesList(data: any): Promise<any> {
        return API.post(`/attendancesList`, data);
    }
    static getByBatchMeet(data: any): Promise<any> {
        return API.post(`/getByBatchMeet`, data);
    }

    static allTasksData(data: any): Promise<any> {
        return API.post(`/allTasksData`, data);
    }

    
    

    static createCgpa(data: any): Promise<any> {
        return API.post(`/createCgpa`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    static cgpaList(data: any): Promise<any> {
        return API.post(`/cgpaList`, data);
    }
    static careerGuidanceList(data: any): Promise<any> {
        return API.post(`/careerGuidanceList`, data);
    }
    static createCareerGuidance(data: any): Promise<any> {
        return API.post(`/createCareerGuidance`, data);
    }
    static changePassword(data: any): Promise<any> {
        return API.post(`/changePassword`, data);
    }

    //updatePersonalInfo

    static updatePersonalInfo(data: any): Promise<any> {
        return API.post(`/updateUserProfile`, data);
    }

    ///uploadUserPic

    static uploadProfile(data: any): Promise<any> {
        return API.post(`/uploadUserPic`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}