import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import moment from "moment";
import { UserService } from "../../../app/service/user.service";
import { toast } from "react-toastify";
import certifcateImg from "../../../assets/images/user_cerificates/cerificate_img.jpg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import signImg from "../../../assets/images/img/sign.png";


const UserAttendance = () => {
  const [tableData, setTableData] = useState([]);

  const dateFormat = (dateStr: any) => {
    const date = moment(dateStr);
    const formattedDate = date.format("DD/MMM/YYYY");
    return formattedDate;
  };

  useEffect(() => {
    getLoas();
  }, []);

  const getLoas = () => {
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    const email = userRole?.email;
    const data = { email: email };

    UserService.attendancesList(data)
      .then((res) => {
        if (res.status === "Success") {
          setTableData(res.data);
        } else {
          // toast.error(res.message);
        }
      })
      .catch(() => {
        toast.error("An error occurred while fetching data.");
      });
  };
  const handleView = async (item: any) => {
    // Prevent the default action (like navigation)
    // event.preventDefault();

    // Generate the HTML content for the LOA
    let certificateHtml = ReactDOMServer.renderToString(loaGenerate(item));

    // Create a container to hold the generated HTML content
    const container = document.createElement("div");
    // container.style.position = "fixed";
    container.style.top = "0";
    container.style.left = "0";
    container.style.width = "auto";
    container.style.height = "auto";
    container.style.overflow = "hidden"; // Ensure no overflow
    container.style.position = 'absolute';
    container.style.top = '-9999px';
    container.innerHTML = certificateHtml;
    document.body.appendChild(container);

    // Wait for the content to render
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Render the HTML content to canvas
    const canvas = await html2canvas(container, {
      scale: 2,
      useCORS: true,
      scrollX: 0,
      scrollY: 0,
      width: container.scrollWidth, // Match container dimensions
      height: container.scrollHeight,
    });

    // Remove the temporary HTML container after rendering
    document.body.removeChild(container);

    // Convert the canvas to image data
    const imgData = canvas.toDataURL("image/png");

    // Initialize jsPDF with portrait orientation
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [canvas.width, canvas.height], // Set dimensions to match the canvas
    });

    // Set the page height and calculate total pages
    const pageHeight = pdf.internal.pageSize.getHeight();
    let remainingHeight = canvas.height;
    let position = 0;

    while (remainingHeight > 0) {
      pdf.addImage(imgData, "PNG", 0, position, canvas.width, pageHeight);

      remainingHeight -= pageHeight;
      position -= pageHeight;

      if (remainingHeight > 0) {
        pdf.addPage(); // Create a new page if more content remains
      }
    }

    // Save the generated PDF
    pdf.save("Loa.pdf");
  };

  const loaGenerate = (data: any) => {
    const date = new Date(data.createdAt).toLocaleDateString();
    // const startDate =  new Date(data.loaStartDate).toLocaleDateString();
    // const endDate =  new Date(item.endDate).toLocaleDateString();
    const startDate =  new Date(data.attendanceStartDate).toLocaleDateString();
    const endDate =  new Date(data.attendanceEndDate).toLocaleDateString();
    // const startDate = dateFormat(data.loaStartDate);
    // const endDate = dateFormat(data.loaEndDate);
    return (
      <>
        <div className="main-section-loa">
          <div className="container">
            <div className="row">
              <div className="col-md-12 p-5">
                <div className="pt-5">
                  <p className="text-center">
                    <b>Certificate of Attendance</b>,
                  </p>
                  <p>
                    <b>Presented to {data?.userName},</b>
                  </p>
                  <p>
                    This is to certify that
                    <b>&nbsp;{data?.userName}</b> in a student of
                    <b>&nbsp;{data?.collegeName} ,</b> has successfully completed the required
                    attendance for the
                    <b> {data?.attendanceCourseName}</b>. held from &nbsp;
                    <b>{startDate}</b> to
                    <b>&nbsp;{endDate}</b>&nbsp;at Eduexpose.
                  </p>
                  <p className="mt-5">
                   During the program, the student achieved 70% attendance
                  </p>
                  <p className="mt-5">
                    We hereby confirm that the student’s attendance record is
                    accurate and valid for submission to their institution.
                  </p>
                  <p className="mt-5">
                    Eduexpose affirms that the above information is true to the
                    best of our knowledge.
                  </p>
                  <p>
                    <b>Date of Issue:</b> {date}
                  </p>
                  <p>Best regards,</p>
                  <div>
                    <img src={signImg} width="150px" alt="" />
                  </div>
                  <p className="mt-3">
                    <b>Sridhar</b>
                  </p>
                  <p>Chief Operating Officer,</p>
                  <p>Eduexpose</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          {tableData?.length > 0 ? (
            <>
              <div className="col-md-10 content-scroll content-bg mt-5 px-5">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ color: "white" }} className="primary-bg">
                        Domain Name
                      </th>
                      <th style={{ color: "white" }} className="primary-bg">
                        Internship Period
                      </th>
                      <th style={{ color: "white" }} className="primary-bg">
                        Issue Dates
                      </th>
                      <th style={{ color: "white" }} className="primary-bg">
                        LOA
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{item.attendanceCourseName}</td>
                          <td>
                            {dateFormat(item.attendanceStartDate)} to{" "}
                            {dateFormat(item.attendanceEndDate)}
                          </td>
                          <td>{dateFormat(item.createdAt)}</td>
                          <td>
                            <button
                              className="bg-transparent text-black border border-black px-4 py-1 mt-2 primary-btn w-75"
                              style={{ borderRadius: "5px" }}
                              onClick={() => handleView(item)}
                            >
                              view
                            </button>
                            <br />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-10 content-scroll content-bg px-5 mt-5 text-center">
                <br />
                <br />
                <br />
                <br />
                <img
                  src={certifcateImg}
                  width="30%"
                  height="50%"
                  className="mt-5"
                />
                <h4 className="">
                  <b>
                    {" "}
                    Empty <span className="primary-color">LOA</span>
                  </b>
                </h4>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserAttendance;
