import React, { useState, useEffect } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { QueriesDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const QueriesList = () => {
  const [name, setName] = useState("");
  const [registersData, setRegistersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  const navigate = useNavigate();

  const handleCancel = () => {
    setName("");
    setFilteredData(registersData); // Reset to full list
  };

  const handleSearch = () => {
    const filtered = registersData.filter((query: any) =>
      query.email.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    getRegisters();
  }, []);

  const getRegisters = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllQueriesList(data).then((res: any) => {
      if (res.status === "Success") {
        setRegistersData(res.data);
        setFilteredData(res.data); // Initialize filtered data
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteQueriesInfo = (queriesId: string) => {
    const payload = {
      queriesId: queriesId,
    };
    AdminService.deleteQueries(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getRegisters();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <h5><b>Queries List</b></h5>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the Email"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredData} // Use filtered data here
                TableCols={QueriesDataGridCols}
                deleteQueriesInfo={deleteQueriesInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueriesList;
