import React from 'react'

export const AboutProgram = (props:any) => {
  return (
    <div className='px-2 mx-md-3 text-center'>
    <div className='fs-1 fw-bold'>About this <span className='primary-color'>program</span></div>
    <div className='row '>
        <div className='col-md-12 text-center'>
            <div className='secondary-color py-3'>
                {props?.data?.about}
         </div>
        </div>
    </div>
</div>
  )
}
