import React from "react";
import RewardOne from "./../assets/images/ambassador/Rewards Offered.png";
import Rewardtwo from "./../assets/images/ambassador/Rewards Offered-2.png";
import Rewardthree from "./../assets/images/ambassador/Rewards Offered-3.png";



export const RewardsOfferedCards = () => {
  return (
    <div className="d-md-flex justify-content-between mb-4">
      <div className="card primary-bg text-white my-3 my-md-0 mx-md-4">
        <img
          src={RewardOne}
          className="card-img-top trending-domain-card-image"
          alt="..."
        />
        <div className="card-body border-top-0 border rounded-bottom border-white border-top-0">
          <h5 className="card-title text-center">Gold Reward : Iphone </h5>
          {/* <p className="card-text secondary-color text-center">
            This is a longer card with supporting text below as a natural
            lead-in to additional content. This content is a little bit longer.
          </p> */}
        </div>
      </div>
      <div className="card primary-bg text-white my-3 my-md-0 mx-md-4">
        <img
          src={Rewardthree}
          className="card-img-top trending-domain-card-image"
          alt="..."
        />
        <div className="card-body border-top-0 border rounded-bottom border-white border-top-0">
          <h5 className="card-title text-center">
            Silver Reward : Marketing internship opportunity
          </h5>
          {/* <p className="card-text secondary-color text-center">
            This is a longer card with supporting text below as a natural
            lead-in to additional content. This content is a little bit longer.
          </p> */}
        </div>
      </div>
      <div className="card primary-bg text-white my-3 my-md-0 mx-md-4">
        <img
          src={Rewardtwo}
          className="card-img-top trending-domain-card-image"
          alt="..."
        />
        <div className="card-body border-top-0 border rounded-bottom border-white border-top-0">
          <h5 className="card-title text-center">
            Bronze Reward : Incentive upto 15k
          </h5>
          {/* <p className="card-text secondary-color text-center">
            This is a longer card with supporting text below as a natural
            lead-in to additional content. This content is a little bit longer.
          </p> */}
        </div>
      </div>
    </div>
  );
};
