import React from 'react'

export const OurStory = () => {
    return (
        <div className='px-2 mx-md-5 text-start'>
            <div className='medium-header'>Our <span className='primary-color'>Goal</span></div>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='secondary-color py-3'>
                        Eduexpose is to make education more accessible to learners. This could involve offering online courses that enable learners to access educational resources .
                        Eduexpose  often aim to improve learning outcomes by leveraging technology to create engaging, interactive, and personalized learning experiences.
                    </div>
                    <div className='secondary-color '>
                        Another key goal is to increase student engagement and motivation through innovative teaching methods and interactive learning tools. This could include virtual classrooms, discussion forums, peer collaboration features, and real-world projects that encourage active participation and foster a sense of community among learners.
                        We seek to forge partnerships and collaborations with educational institutions, businesses, governments, and other stakeholders to expand our reach, scale our impact, and create synergies that benefit learners and educators alike. It involve co-developing courses, conducting research projects, or implementing initiatives to address specific educational challenges or societal needs.                    </div>
                    <div className='secondary-color py-3'>
                    Finally,Eduexpose aim to ensure the sustainability and scalability of our operations by developing robust business models, investing in infrastructure and technology, and continuously innovating to stay competitive in a rapidly evolving market. It involves balancing financial viability with social impact and striving for long-term growth and success while remaining true to their mission and values.
                    </div>
                </div>
                <div className='col-md-6 d-none'>
                    <div className='secondary-color py-3'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                    </div>
                    <div className='secondary-color '>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </div>
                    <div className='secondary-color py-3'>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </div>
                </div>
            </div>
        </div>
    )
}
