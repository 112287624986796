import React, { useState, useEffect } from 'react';
import { Header } from '../../../components/user-dashboard/header';
import { Sidebar } from '../../../components/user-dashboard/sidebar';
import { DataTable } from '../../../components/tables';
import { PaymentsDataGridCols } from './data-grid-cols';


const PaymentsList = () => {
    const [name, setName] = useState("");
    let usersData: any = []

    const handleCancel = () => {
        setName("")
    }
    const handleSearch = () => {

    }
    const handleCreateUser=()=> {
  
    }

    return (
        <div>
        <div className='container-fluid'>
            <div className='row'>
                <Header />
            </div>
            <div className='row'>
                <div className='col-md-2 h-100 px-0'>
                    <Sidebar />
                </div>
                <div className='col-md-10 content-scroll content-bg px-3 py-3'>
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <label className="form-label d-flex justify-content-start text-start">
                            <b>Payments List</b>
                            </label>
                            <div className="d-flex">
                                <input
                                    name="User Name"
                                    onChange={(e: any) => setName(e.target.value)}
                                    className="form-control mt-1 me-2"
                                    value={name}
                                    placeholder='Enter the Payment Id'
                                />
                                <button className="btn btn-primary rounded px-4 py-1" onClick={() => handleSearch()}>
                                    Search
                                </button>
                                <button className="btn btn-secondary rounded ms-2 px-4 py-1" onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            {/* <div className='text-end'>
                                <button className="btn btn-success" onClick={handleCreateUser}>
                                    Create User
                                </button>
                            </div> */}
                        </div>
                    </div>
                    <div className="mt-5">
                        <DataTable
                            tableData={usersData}
                            TableCols={PaymentsDataGridCols}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    )
}

export default PaymentsList;