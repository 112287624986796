import { DataTableCol } from "../../../components/tables/types";

export const ManageCourseDataGridCols: DataTableCol[] = [
  {
    title: "Course Name",
    control: "courseName",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Amount",
    control: "amount",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Total Seats",
    control: "totalSeats",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Sold Seats",
    control: "soldSeats",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: true,
    isDelete: true,
    canShowColumn: true,
  },
];
