import React, { useEffect } from "react";
import StepOne from "./../../assets/images/ambassador-works/step-one.png";
import StepTwo from "./../../assets/images/ambassador-works/step-two.png";
import StepThree from "./../../assets/images/ambassador-works/step-three.png";
import Line from "./../../assets/images/ambassador-works/line.png";
import ParticipationCertificates from "./../../assets/images/cerificates/participate.jpg";
import InternshipCertificates from "./../../assets/images/cerificates/intenship-c.jpg";
import TrainingCertificates from "./../../assets/images/cerificates/cerificate.jpg";
import Swal from "sweetalert2";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import ReactDOMServer from "react-dom/server";


export const SampleCertification = (props: any) => {
    // let title = props?.data?.courseName;
    // let amount =  props?.data?.amount;

    // useEffect(() => {
    //     Swal.fire({
    //       title: title,
    //       html: `<div style="font-size: 24px;">${ReactDOMServer.renderToString(
    //         <RiMoneyRupeeCircleFill style={{ fontSize: "49px" }} />
    //       )} ${amount}</div>`,
    //       icon: "info",
    //       confirmButtonText: "OK",
    //     });
    //   }, []);
    
  return (
    <div>
      <div className="px-2 mx-md-5 d-none d-md-block">
        <div>
          <div className="fs-2 fw-bold text-center">
            Sample <span className="primary-color">Certification</span>
          </div>
        </div>
        <div className="row d-flex mx-auto mt-5">
          <div className="col-md-4 text-start mb-3">
            <div className="fs-5 fw-bold">Participation Certificate</div>
            <div className="fs-6 secondary-color my-3">
              {props?.data?.participationCertification}
            </div>
          </div>
          <div className="col-md-4 mx-auto text-center">
            <div>
              <img src={StepOne} />
            </div>
            <div>
              <img src={Line} />
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <div>
              <img
                src={ParticipationCertificates}
                className="rounded img-fluid"
                width="100%"
                height="90%"
              />
            </div>
          </div>
        </div>
        <div className="row d-flex mx-auto my-3">
          <div className="col-md-4 d-flex justify-content-start">
            <div>
              <img
                src={TrainingCertificates}
                width="100%"
                height="90%"
                className="rounded img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 mx-auto text-center">
            <div>
              <img src={StepTwo} />
            </div>
            <div>
              <img src={Line} />
            </div>
          </div>
          <div className="col-md-4 text-start d-flex justifyc-content-start">
            <div>
              <div className="fs-5 fw-bold">Training Certificate</div>
              <div className="fs-6 secondary-color my-3">
                {props?.data?.trainingCompletioncertification}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex mx-auto mt-5 my-3">
          <div className="col-md-4 text-start">
            <div className="fs-5 fw-bold">Internship Certificate</div>
            <div className="fs-6 secondary-color my-3">
              {props?.data?.internshipCompletioncertification}{" "}
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div>
              <img src={StepThree} />
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <div>
              <img
                src={InternshipCertificates}
                width="100%"
                height="90%"
                className="rounded img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="px-2 mx-md-5 d-block d-md-none">
        <div>
          <div className="fs-2 fw-bold text-center">
            Sample <span className="primary-color">Certification</span>
          </div>
        </div>
        <div className="mt-4">
          <div className="row">
            <div className="col-3 col-md-4 step-number text-center">
              <div className="col-12">
                <img src={StepOne} />
              </div>
              <div className="col-12">
                <img src={Line} />
              </div>
            </div>
            <div className="col-9 col-md-4 step-content ps-2 mb-3 text-start">
              <img className="img-fluid" src={ParticipationCertificates} />
              <h5 className="mt-2">Participation Certificate</h5>
              <p className="step-description">
                Celebrate your active engagement and dedication to mastering AI
                with our Participation Certification. This prestigious
                credential acknowledges your commitment to learning and
                showcases your enthusiasm for advancing your skills in
                artificial intelligence. By completing our Advanced AI
                Engineering Program, you've demonstrated a proactive approach to
                shaping the future of technology. Display your certification
                proudly on your professional profiles and resumes to highlight
                your expertise and commitment to excellence in AI engineering.
                Congratulations on your achievement and best wishes for your
                continued success in the field of artificial intelligence!
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-3 col-md-4 step-number text-center">
              <div className="col-12">
                <img src={StepTwo} />
              </div>
              <div className="col-12">
                <img src={Line} />
              </div>
            </div>
            <div className="col-9 col-md-4 step-content ps-2 mb-3 text-start">
              <img className="img-fluid" src={TrainingCertificates} />
              <h5 className="mt-2">Training Certificate</h5>
              <p className="step-description">
                Congratulations on successfully completing our rigorous Advanced
                AI Engineering Program! This certification recognizes your
                dedication and proficiency in mastering essential AI concepts
                and techniques. Showcase your expertise and readiness to tackle
                real-world challenges in artificial intelligence by displaying
                this prestigious certification on your professional profiles and
                resumes.{" "}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-3 col-md-4 step-number text-center">
              <div className="col-12">
                <img src={StepThree} />
              </div>
              <div className="col-12">
                <img src={Line} />
              </div>
            </div>
            <div className="col-9 col-md-4 step-content ps-2 text-start">
              <img className="img-fluid" src={InternshipCertificates} />
              <h5 className="mt-2">Internship Certificate</h5>
              <p className="step-description">
                Embark on your AI journey with practical experience gained
                through our internship program. Congratulations on successfully
                completing your internship in our Advanced AI Engineering
                Program! This certification acknowledges your outstanding
                contributions and practical skills developed during your time
                with our team. Highlight your hands-on experience and readiness
                for the AI industry by displaying this certification proudly.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
