import React from 'react'
import { NavBar } from '../components/nav-bar'
import { Footer } from '../components/footer'
import { AboutEduExpose } from '../components/about-us/about-eduexpose'
import { OurStory } from '../components/about-us/our-story'
import { OurLeaderShipTeam } from '../components/about-us/our-leader-ship-team'
import { OurPartners } from '../components/about-us/our-partners'
// import { TakOurWord } from '../components/home-page-components/tak-our-word'
import { OurVision } from '../components/about-us/our-vision'

export const AboutUs = () => {
  return (
    <div className='container-fluid scroll-bar-width-none primary-bg pt-5' style={{ backgroundColor: "#1d130f", color: "white" }}>
      <div className="row">
        <div className="col-md-12">
          <NavBar activeMenu = {"aboutus"}/>
        </div>
        <div className='border-bottom border-white mt-5 mb-0 pb-0 pb-md-4 text-center text-white'>
          <AboutEduExpose/>
        </div>
        <div className='border-bottom border-white mt-5 mb-4 pb-4 text-white'>
          <OurStory/>
        </div>
        {/* <div className='border-bottom border-white my-5 pb-5'>
          <OurLeaderShipTeam/>
        </div> */}
        <div className='border-bottom border-white mt-0 mb-3 mb-md-3 mb-lg-5 pb-3 pb-md-5 text-white'>
          <OurVision/>
        </div>
        {/* <div className='border-bottom border-white my-5 pb-5'>
          <OurPartners/>
        </div>
        <div className='border-bottom border-white my-5 pb-5'>
          <TakOurWord/>
        </div> */}
        <div className='col-md-12 border-bottom border-light mt-3 text-white'>
          <Footer />
        </div>
      </div>
    </div >
  )
}
