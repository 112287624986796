import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { RiDownload2Fill } from "react-icons/ri";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import userDashboardimage from "./../../../assets/images/dashboard/Dashboard image (5).png";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import HomeTable from "../../../components/HomeTable/HomeTable";
import { UserService } from "../../../app/service/user.service";

export const UserDashboard = () => {
  const { showLoader, hideLoader }: any = useAppContext();

  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const userdata: any = sessionStorage.getItem("loginData");
  const [taskData,setTaskData]=useState([]);
  const userInfo = JSON.parse(userdata);
  const courses = userInfo.courseName;
  const percentage = 66;
  let id = 1;
  let tableHeader = [
    {
      title: "#",
      control: "id",
    },
    {
      title: "Session Time",
      control: "meetDate",
    },
    {
      title: "Title",
      control: "title",
    },
    {
      title: "Execute",
      control: "join",
    },
  ];

  useEffect(() => {
    getMeets();
    getAllTasksData();
  }, []);

  const getMeets = () => {
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);

    showLoader();
    let payload={
      batchName:userRole?.userBatchName
    }

    UserService.getByBatchMeet(payload)
      .then((res: any) => {
        if (res.status === "Success") {   
          setTableData(res.data);
        } 
        hideLoader();
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
        hideLoader();
      });
  };

  const getAllTasksData = () => {
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    let payload={
      batchName:userRole?.userBatchName,
      courseName:userRole?.courseName[0]
    }

    UserService.allTasksData(payload)
      .then((res: any) => {
        if (res.status === "Success") {   
          setTaskData(res.data);
        } 
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
      });
  };


  

  const handleLms = () => {
    navigate("/user/cms");
  };
  const handleCalendar = () => {
    navigate("/user/calendar");
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-6 mt-4">
          <div className="px-3 py-3 rounded learning-full-container">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h3 className="text-start text-white">
                  Learning is <span className="primary-color-two">Fun!</span>
                </h3>
                <p className="text-start text-white">
                  Welcome to your personalized learning dashboard! We're
                  thrilled to have you on board as you embark on your upskilling
                  journey
                </p>
                <div className="text-start">
                  <button
                    className="button button1 border-0 px-5 py-1"
                    onClick={() => handleLms()}
                  >
                    LMS
                  </button>
                </div>
              </div>
              <div>
                <img
                  src={userDashboardimage}
                  alt="Image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 shadow rounded-3 mt-4">
          <div className="ourcoursescard py-3">
            <h3 className="text-start">
              Domain <span className="primary-color-two">Progress</span>
            </h3>
            <div className="row">
              {courses.map((course: any, index: number) => (
                <div
                  className="col-md-12 d-flex align-items-center mt-3"
                  key={index}
                >
                  {/* Left side: Circular Progress */}
                  <div className="col-md-6 text-center">
                    <CircularProgressbar
                      className="w-25" // Adjust width for better appearance
                      value={percentage}
                      text={`${percentage}%`}
                    />
                    <p className="fw-bold mt-2">{course}</p>
                    <button className="button button1 px-4 py-2 bg-transparent">
                      Watch videos
                    </button>
                  </div>

                  {/* Right side: Content */}
                  <div className="col-md-6 text-start">
                    <h6 className="">
                      {" "}
                      <b>
                        {" "}
                        Dear {userInfo?.name}, <br />
                        you are{" "}
                        <span className="primary-color">Three steps</span>
                        &nbsp;away <br />
                        to get certified
                      </b>
                    </h6>

                    {courses.length === 2 && (
                      <div className={index === 0 ? "text-end mt-4" : "mt-4"}>
                        {index === 0 ? (
                          <MdArrowForwardIos
                            style={{
                              fontSize: "30px",
                              border: "2px solid",
                              borderRadius: "50%",
                              padding: "3px",
                              float: "right",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <MdArrowBackIosNew
                            style={{
                              fontSize: "30px",
                              border: "2px solid",
                              borderRadius: "50%",
                              padding: "3px",
                              float: "left",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <br />
      {/*  */}
      <div className="row">
        <div className="col-md-6 mt-3">
          <div
            className="ourcoursescard px-3 py-5 shadow rounded-4 position-relative"
            style={{ height: "400px" }}
          >
            <h3 className="text-start">
              <span
                className="primary-color-two pointer"
                onClick={() => handleCalendar()}
              >
                Learning
              </span>
              &nbsp; Schedule
            </h3>
            {/* <div className="d-flex justify-content-between mb-3">
              <div className="text-start">
                <input
                  type="text"
                  className="form-control w-100 rounded"
                  placeholder="Search...."
                />
              </div>
              <div className="text-end ">
                <button className="downloaded_button px-4 py-1 bg-transparent">
                  <RiDownload2Fill style={{ fontSize: "24px" }} />
                  &nbsp;&nbsp;curriculum
                </button>
              </div>
            </div> */}
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  {tableHeader.map((item, index) => (
                    <th key={index}>{item.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row: any, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={rowIndex % 2 === 0 ? "even-row" : "odd-row"}
                  >
                    {tableHeader.map((header, headerIndex) => {
                      if (header.control === "id") {
                        const generatedId = id++;
                        return <td key={headerIndex}>{generatedId}</td>;
                      }
                      if (header.control === "join") {
                        return (
                          <td key={headerIndex}>
                            <a
                              href={row.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Join
                            </a>
                          </td>
                        );
                      }
                      if (header.control === "meetDate") {
                        return <td key={headerIndex}>{row.meetDate}</td>;
                      }
                      return <td key={headerIndex}>{row[header.control]}</td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-6 my-4">
          <div className="ourcoursescard px-3 py-3 shadow rounded-4 h-100">
            <h3 className="text-start">
              <span className="guide-custom-color">Worksheets</span>
              &nbsp;Submissions
            </h3>
            {/* <video width="100%" controls>
              <source
                src="https://www.youtube.com/watch?v=QFaFIcGhPoM&list=PLC3y8-rFHvwgg3vaYJgHGnModB54rxOk3"
                type="video/mp4"
              />
              <source
                src="https://www.youtube.com/watch?v=QFaFIcGhPoM&list=PLC3y8-rFHvwgg3vaYJgHGnModB54rxOk3"
                type="video/ogg"
              />
              Your browser does not support HTML video.
            </video> */}

            <HomeTable taskData={taskData}/>
          </div>
        </div>
      </div>
    </div>
  );
};
