import React, { useState } from "react";

export const FrequentlyAskedQuestions = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (id: any) => {
    if (activeAccordion === id) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(id);
    }
  };

  return (
    <div className="px-2 mx-md-5 text-center">
      <div className="col-md-12">
        <div className="medium-header ">
          Frequently Asked <span className="primary-color">Questions</span>
        </div>
        <div className="questions-list w-75 mx-auto bg-transparent my-4">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item border">
              <h2 className="accordion-header border-bottom-0">
                <button
                  className={`accordion-button shadow-none border-bottom-0 faq-fontfamily faq-font ${
                    activeAccordion === "flush-collapseFive" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => toggleAccordion("flush-collapseFive")}
                >
                  What is an{" "}
                  <span className="primary-color tab-cnt-span ps-2">
                    Ambassador program?
                  </span>
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className={`accordion-collapse collapse ${
                  activeAccordion === "flush-collapseFive" ? "show" : ""
                }`}
              >
                <div className="accordion-body pt-0">
                  As an Ambassador, your work is to :-
                  <br />
                  - Promote Eduexpose Certification & internships in your
                  college and other friends and make their queries clear
                  <br />
                  - Inform your friends about eduexpose internship program and
                  make them get registered for the same
                  <br />- Promote the circular across your classmates and
                  juniors to join the webinar for Certification and Internship.
                </div>
              </div>
            </div>
            <div className="accordion-item border">
              <h2 className="accordion-header bg-transparent border-bottom-0">
                <button
                  className={`accordion-button shadow-none border-bottom-0 border-bottom-transparent faq-fontfamily faq-font ${
                    activeAccordion === "flush-collapseOne" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => toggleAccordion("flush-collapseOne")}
                >
                  Can we do this&nbsp;
                  <span className="primary-color tab-cnt-span">
                    work online?
                  </span>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse border-top-0 collapse ${
                  activeAccordion === "flush-collapseOne" ? "show" : ""
                }`}
              >
                <div className="accordion-body pt-0">
                  Yes ,as an ambassador you can work from anywhere.
                </div>
              </div>
            </div>
            <div className="accordion-item border">
              <h2 className="accordion-header border-bottom-0">
                <button
                  className={`accordion-button shadow-none border-bottom-0 faq-font faq-fontfamily ${
                    activeAccordion === "flush-collapseTwo" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => toggleAccordion("flush-collapseTwo")}
                >
                  Do we have to pay any
                  <span className="primary-color tab-cnt-span ps-2">
                    {" "}
                    fees for the ambassador program?
                  </span>
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className={`accordion-collapse collapse ${
                  activeAccordion === "flush-collapseTwo" ? "show" : ""
                }`}
              >
                <div className="accordion-body pt-0">
                  As an ambassador all the benefits which you are availing are
                  totally free of cost and from our partnered brands and
                  restaurants you may avail discounts too.
                </div>
              </div>
            </div>
            <div className="accordion-item border">
              <h2 className="accordion-header border-bottom-0">
                <button
                  className={`accordion-button shadow-none border-bottom-0 faq-font faq-fontfamily ${
                    activeAccordion === "flush-collapseThree" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => toggleAccordion("flush-collapseThree")}
                >
                  Can I get any&nbsp;
                  <span className="primary-color tab-cnt-span ps-2">
                    certificate?
                  </span>
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className={`accordion-collapse collapse ${
                  activeAccordion === "flush-collapseThree" ? "show" : ""
                }`}
              >
                <div className="accordion-body pt-0">
                  Yes, you are eligible for the excellence certification from
                  eduexpose as well as LOR that will helps you to build your
                  resume more stronger for recruiters
                </div>
              </div>
            </div>
            <div className="accordion-item border">
              <h2 className="accordion-header border-bottom-0">
                <button
                  className={`accordion-button shadow-none border-bottom-0  faq-fontfamily faq-font ${
                    activeAccordion === "flush-collapseFour" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => toggleAccordion("flush-collapseFour")}
                >
                  How can I{" "}
                  <span className="primary-color tab-cnt-span ps-2">
                    apply for this ?
                  </span>
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className={`accordion-collapse collapse ${
                  activeAccordion === "flush-collapseFour" ? "show" : ""
                }`}
              >
                <div className="accordion-body pt-0">
                  You can fill the form so one of our recruiters will contact
                  you once you get shortlisted.
                </div>
              </div>
            </div>
            {/* <div className="accordion-item border">
              <h2 className="accordion-header border-bottom-0">
                <button
                  className={`accordion-button shadow-none border-bottom-0 faq-fontfamily faq-font ${
                    activeAccordion === "flush-collapseFive" ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={() => toggleAccordion("flush-collapseFive")}
                >
                  What is an{" "}
                  <span className="primary-color tab-cnt-span ps-2">
                    Ambassador program?
                  </span>
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className={`accordion-collapse collapse ${
                  activeAccordion === "flush-collapseFive" ? "show" : ""
                }`}
              >
                <div className="accordion-body pt-0">
                  As an Ambassador, your work is to :-
                  <br />
                  - Promote Eduexpose Certification & internships in your
                  college and other friends and make their queries clear
                  <br />
                  - Inform your friends about eduexpose internship program and
                  make them get registered for the same
                  <br />- Promote the circular across your classmates and
                  juniors to join the webinar for Certification and Internship.
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="my-4 secondary-color">
          Have a question that is not answered? You can contact us at{" "}
          <span className="fw-bold fs-6 text-white">support@eduexpose.in</span>
        </div>
      </div>
    </div>
  );
};
