import { useState } from "react";

export const ProgramCurriculum = (props: any) => {
  const [openModuleIndex, setOpenModuleIndex] = useState(-1);
  const [modulesToShow, setModulesToShow] = useState(5);

  const toggleShowAllModules = () => {
    setModulesToShow(
      modulesToShow === props?.data?.curriculum?.length
        ? 5
        : props?.data?.curriculum?.length
    );
    setOpenModuleIndex(-1); // Reset openModuleIndex when toggling modules
  };

  return (
    <div className="px-2 mx-md-5">
      <div className="col-md-12">
        <div className="fs-1 fw-bold">
          Program <span className="primary-color">Curriculum</span>
        </div>
        <div className="questions-list curriculum-accordion mx-auto bg-transparent my-4">
          <div className="accordion" id="accordionExample">
            {props?.data?.curriculum
              ?.slice(0, modulesToShow)
              ?.map((module: any, index: number) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className={`faq-btn-sha accordion-button fs-4 fw-bold ${
                        openModuleIndex === index ? "" : "collapsed"
                      }`}
                      type="button"
                      onClick={() =>
                        setOpenModuleIndex(openModuleIndex === index ? -1 : index)
                      }
                      aria-expanded={openModuleIndex === index ? "true" : "false"}
                      aria-controls={`collapse${index}`}
                    >
                      <span  className="primary-color faq-display">{`Module ${
                        index + 1
                      }: `}</span>
                      {module.category}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${
                      openModuleIndex === index ? "show" : ""
                    }`}
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {module.topics.map((topic: string, topicIndex: number) => (
                        <p key={topicIndex}>{topic}</p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            {props?.data?.curriculum?.length > 5 && (
              <div className="my-4">
                <button
                  className="bg-transparent px-5 py-2 primary-btn text-white border border-secondary"
                  onClick={toggleShowAllModules}
                >
                  {modulesToShow === props?.data?.curriculum?.length
                    ? "Show Less"
                    : "Load More"}
                </button>
              </div>
            )}
            <div className="my-4 secondary-color">
              Want to know more?{" "}
              <span className="fw-bold fs-6 text-white text-decoration-underline">
                Request info
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
