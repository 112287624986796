import { DataTableCol } from "../../../components/tables/types";

export const projectDataGridCols: DataTableCol[] = [
    {
        title: 'Domain',
        control: 'courseName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Project Name',
        control: 'projectName',
        sortable: true,
        canShowColumn: true
    },
    // {
    //     title: 'Actions',
    //     control: 'both',
    //     sortable: false,
    //     isEdit: false,
    //     isDelete: false,
    //     canShowColumn: true,
    //     isCerificate:true
    // },
];