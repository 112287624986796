import React, { useState, useEffect } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { RegisterDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";
import * as XLSX from "xlsx";

const RegisterList = () => {
  const [name, setName] = useState("");
  const [registersData, setRegistersData] = useState([]);
  const [filteredRegisters, setFilteredRegisters] = useState([]);
  const navigate = useNavigate();

  const handleCancel = () => {
    setName("");
    setFilteredRegisters(registersData); // Reset the filtered data to the original list
  };

  const handleSearch = () => {
    const filteredData = registersData.filter((register: any) =>
      register.name.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredRegisters(filteredData);
  };

  useEffect(() => {
    getRegisters();
  }, []);

  const getRegisters = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
      type: "registerNow",
    };
    AdminService.getAllRegisterList(data).then((res: any) => {
      if (res.status === "Success") {
        if (res.data.result) {
          const formattedData = res.data.result.map((item: any) => {
            return {
              ...item,
              createdAt: moment(item.createdAt).format("DD MMM YYYY, hh:mm A")
            };
          });
          setRegistersData(formattedData);
          setFilteredRegisters(formattedData);
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteRegisterInfo = (userId: string) => {
    const payload = {
      userId: userId,
    };
    AdminService.deleteRegister(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getRegisters();
      } else {
        toast.error(res.message);
      }
    });
  };

  const exportData = () => {
    const ws = XLSX.utils.json_to_sheet(filteredRegisters);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Register Data");

    // Set the file name
    const fileName = "RegisterList.xlsx";

    // Write the Excel file
    XLSX.writeFile(wb, fileName);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <h5>
                    <b>Prospect List</b>
                  </h5>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the User Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="text-end">
                  <button className="btn btn-success" onClick={exportData}>
                    Export
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredRegisters}
                TableCols={RegisterDataGridCols}
                deleteRegisterInfo={deleteRegisterInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterList;
