import React, { useState } from "react";
import Logo_two from "../assets/images/Logi.png";
import { courses, modeOfCommunication, years } from "../config";
import { UserService } from "../app/service/user.service";
import { toast } from "react-toastify";
import image1 from "../assets/images/home_logos/image1.png";
import image2 from "../assets/images/home_logos/image2.png";
import image3 from "../assets/images/home_logos/image3.png";
import image4 from "../assets/images/home_logos/image4.png";

const RegisterNow = () => {
  const [formData, setFormData] = useState({
    emailAddress: "",
    name: "",
    collegeName: "",
    degreeYear: "",
    department: "",
    domainOfInterest: "",
    contactNumber: "",
    whatsAppNumber: "",
    modeOfCommunications: "",
    type: "registerNow",
  });
  const [errors, setErrors] = useState<any>({});
  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.emailAddress) {
      newErrors.emailAddress = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailAddress)) {
      newErrors.emailAddress = "Invalid email format";
    }

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.collegeName) {
      newErrors.collegeName = "College Name is required";
    }

    if (!formData.degreeYear) {
      newErrors.degreeYear = "Degree Year is required";
    }

    if (!formData.department) {
      newErrors.department = "Department is required";
    }

    if (!formData.domainOfInterest) {
      newErrors.domainOfInterest = "Domain of Interest is required";
    }

    if (!formData.contactNumber) {
      newErrors.contactNumber = "Contact Number is required";
    }

    if (!formData.whatsAppNumber) {
      newErrors.whatsAppNumber = "WhatsApp Number is required";
    }

    if (!formData.modeOfCommunications) {
      newErrors.modeOfCommunications = "Mode of Communications is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = (e: any) => {
    e.preventDefault();

    if (validate()) {
      UserService.addRegister(formData).then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success("Successfully Registered");
        }
      });
    }
  };

  return (
    <div className="container">
      <div className="mt-3 mb-3 text-center">
        <img src={Logo_two} className="" style={{ height: "65px" }} />
      </div>
      <div
        className="row"
        style={{ borderRadius: "10px", backgroundColor: "rgb(65,42,34)" }}
      >
        <div className="col-md-12 text-white pt-4 pb-4">
          <div className="text-center">
            <img src={image1} alt="" className="me-5 mt-2" />
            <img src={image2} alt="" className="me-5 mt-2" />
            <img src={image3} alt="" className="me-5 mt-2" />
            <img src={image4} alt="" className="me-5 mt-2" />
          </div>
          <h5 className="mt-5 text-center">
            We are proud to announce that our{" "}
            <span className="primary-color">Certificates</span> and{" "}
            <span className="primary-color">Internship</span> Programs are
            certified by <span className="primary-color">AICTE,</span>APSCHE,
            <span className="primary-color">ISO,</span>MSME.
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-3">
          <div className="form-group">
            <label className="register-font">Email address</label>
            <input
              type="email"
              className={`form-control mt-2 ${
                errors.emailAddress ? "is-invalid" : ""
              }`}
              placeholder="Enter Your Email"
              name="emailAddress"
              onChange={handleInput}
            />
            {errors.emailAddress && (
              <div className="invalid-feedback">{errors.emailAddress}</div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group mt-3">
            <label className="register-font">Name</label>
            <input
              type="text"
              className={`form-control mt-2 ${errors.name ? "is-invalid" : ""}`}
              placeholder="Enter Your Name"
              name="name"
              onChange={handleInput}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name}</div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group mt-3">
            <label className="register-font">College Name</label>
            <input
              type="text"
              className={`form-control mt-2 ${
                errors.collegeName ? "is-invalid" : ""
              }`}
              placeholder="Enter Your College Name"
              name="collegeName"
              onChange={handleInput}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.collegeName}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mt-3">
            <label className="register-font">Degree Year</label>
            <select
              className={`form-control mt-2 ${
                errors.degreeYear ? "is-invalid" : ""
              }`}
              onChange={handleInput}
              name="degreeYear"
            >
              <option>Select Year</option>
              {years.map((ele: any, index) => {
                return (
                  <option value={ele.value} key={index}>
                    {ele.name}
                  </option>
                );
              })}
            </select>
            {errors.name && (
              <div className="invalid-feedback">{errors.degreeYear}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mt-3">
            <label className="register-font">Department</label>
            <input
              type="text"
              className={`form-control mt-2 ${
                errors.department ? "is-invalid" : ""
              }`}
              placeholder="Enter Your Department"
              name="department"
              onChange={handleInput}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.department}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mt-3">
            <label className="register-font">Domain Of Interest</label>
            <select
              className={`form-control mt-2 ${
                errors.domainOfInterest ? "is-invalid" : ""
              }`}
              onChange={handleInput}
              name="domainOfInterest"
            >
              <option>Select Courses</option>
              {courses.map((ele: any, index) => {
                return (
                  <option value={ele.value} key={index}>
                    {ele.name}
                  </option>
                );
              })}
            </select>
            {errors.name && (
              <div className="invalid-feedback">{errors.domainOfInterest}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mt-3">
            <label className="register-font">Contact Number</label>
            <input
              type="text"
              className={`form-control mt-2 ${
                errors.contactNumber ? "is-invalid" : ""
              }`}
              placeholder="Enter Contact Number"
              name="contactNumber"
              onChange={handleInput}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.contactNumber}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mt-3">
            <label className="register-font">Whatsapp Number</label>
            <input
              type="text"
              className={`form-control mt-2 ${
                errors.whatsAppNumber ? "is-invalid" : ""
              }`}
              placeholder="Enter Whatsapp Number"
              name="whatsAppNumber"
              onChange={handleInput}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.whatsAppNumber}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mt-3">
            <label className="register-font">Mode Of Communications</label>
            <select
              className={`form-control mt-2 ${
                errors.modeOfCommunications ? "is-invalid" : ""
              }`}
              onChange={handleInput}
              name="modeOfCommunications"
            >
              <option>Select Mode Of Communications</option>
              {modeOfCommunication.map((ele: any, index) => {
                return (
                  <option value={ele.value} key={index}>
                    {ele.name}
                  </option>
                );
              })}
              {errors.name && (
                <div className="invalid-feedback">
                  {errors.modeOfCommunications}
                </div>
              )}
            </select>
          </div>
        </div>
      </div>
      <div className="text-center mt-4 mb-4">
        <button
          className="text-center button4 w-25 pt-2 pb-2"
          onClick={(e: any) => handleRegister(e)}
        >
          Submit Form
        </button>
      </div>
      <br />
    </div>
  );
};

export default RegisterNow;
