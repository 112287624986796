import React, { useRef, useEffect, useState } from "react";
import TakeOurSectionRightImage from "./../../assets/images/take-our-section-right-image.jpg";
import GoogleImgae from "./../../assets/images/google.png";
import acc from "./../../assets/images/reviews/Infosys.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import tcs from './../../assets/images/companies/tcs.png';
import hcl from './../../assets/images/companies/hcl.png';
import cts from './../../assets/images/companies/congizant.png';
import techmahendra from './../../assets/images/companies/techmahendra.png';
import microsoft from './../../assets/images/companies/microsoft.png';
import infoys from './../../assets/images/companies/infoys.png';
import HomeVideo from './../../assets/images/home_video.png';
import mphasis from './../../assets/images/companies/mphasis.png';

interface Props {
  ambassadorReviews?: any;
}

export const TakOurWord = (props: any) => {
  const slider = useRef<Slider>(null);
  const [viewportSize, setViewportSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Ensure slider.current is not null after component mounts
    if (slider.current) {
      // You can add more initialization code here if needed
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (oldIndex: any, newIndex: any) => {
      setTimeout(() => {
        if (slider.current) {
          slider.current.slickPlay();
        }
      }, 1000);
    },
  };

  


  return (
    <div className="px-2 mx-md-5">
      <div className="row">
        <div className="col-md-6 text-start">
          <div>
            <div className="medium-header">
              Don't just take our <span className="primary-color">word</span>
              <br />
              for it
            </div>
          </div>
        
        </div>
        <div className="col-md-6 text-start text-md-center">
          <div className=" text-start text-md-center ms-2">
            <img
              src={TakeOurSectionRightImage}
              className="take-our-section-right-image"
              alt="Right Section"
            />
          </div>
          <div className="fs-5 fw-bold text-md-center text-left ms-md-2">
            Trusted by <span className="primary-color">100+</span> students
          </div>
        </div>
        <div className="slider-container mt-3 mt-sm-5">
          <Slider ref={slider} {...settings}>
            {props?.testimonials.map((testimonial:any, index:any) => (
              <div key={index} className="mb-4">
                <div className="card card-primary-bg px-3 py-3 text-center text-white card-talkourword">
                  <p className="secondary-color mb-3">{testimonial.text}</p>
                  <div className="testimonial-footer">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div className="d-flex align-items-center">
                        <img
                          src={testimonial.image}
                          className="take-our-section-card-image"
                          alt="Profile"
                        />
                        <h6 className="mx-2">{testimonial.name}</h6>
                      </div>
                      <div className="d-flex align-items-center">
                        {/* <img
                          src={testimonial.companyImage}
                          className="google-image"
                          alt="Company Logo"
                        /> */}
                        <h6 className="mx-2">{testimonial.company}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
