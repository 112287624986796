import React from "react";
import HeroImage from "./../../assets/images/hero-image.png";
import { useNavigate } from "react-router-dom";

export const LearningSkills = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDomain=()=>{
    navigate('/alldomains')
    scrollToTop()
  }
  return (
    <div className="px-2 mx-md-5">
      <div className="row d-flex">
        <div className="col-md-6 text-start d-flex align-items-center">
          <div>
            <div className="medium-header">
              Learning skills is <span className="primary-color">easy</span> and{" "}
              <span className="primary-color">fun</span> here!
            </div>
            <div className="secondary-color my-4">
              Acquire new skills and expand your horizons in a setting that
              prioritizes practical learning. Our programs provide clear routes
              to internships and hands-on projects that enhance your job
              prospects.
            </div>
            <div className="">
              <button
                className="bg-white px-3 py-2 secondary-btn"
                onClick={() => handleDomain()}
              >
                See all programs
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <img src={HeroImage} className="w-100" />
        </div>
      </div>
    </div>
  );
};
