import React from "react";
import AboutThree from "./../../assets/images/about-eduexpose/about-three.png";
import Ambassador_one from "./../../assets/images/ambassador-works/Ambassador hero (1).png";
import Ambassador_two from "./../../assets/images/ambassador-works/Ambassador hero (2).png";
import Ambassador_three from "./../../assets/images/ambassador-works/Ambassador hero (3).png";
import Ambassador_four from "./../../assets/images/ambassador-works/Ambassador hero (4).png";
import Ambassador_five from "./../../assets/images/ambassador-works/Ambassador hero (5).png";
import { Navigate, useNavigate } from "react-router-dom";

export const GetAnOpportunity = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleStart = () => {
    navigate("/getstarted");
    scrollToTop();
  };
  return (
    <div className="px-2 mx-md-5">
      <div>
        <div className="hero-section">
          Get an <span className="primary-color">opportunity</span> to be the
          face of <span className="primary-color">Eduexpose</span> in your{" "}
          <span className="primary-color">college</span>
        </div>
        {/* <div className='secondary-color my-5'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </div> */}
        <div className="my-4">
          <button
            className="bg-white px-3 py-2 secondary-btn"
            onClick={() => handleStart()}
          >
            Get started
          </button>
        </div>
      </div>
      <div className="row mx-auto images-container">
        <div className="col-2 p-0 mx-auto">
          <img
            src={Ambassador_five}
            className="ambassador-image rounded mt-5"
          />
        </div>
        <div className="col-2 p-0 mx-auto">
          <img src={Ambassador_three} className="ambassador-image rounded" />
        </div>
        <div className="col-2 p-0 mx-auto">
          <img src={Ambassador_one} className="ambassador-image rounded mt-5" />
        </div>
        <div className="col-2 p-0 mx-auto">
          <img src={Ambassador_two} className="ambassador-image rounded" />
        </div>
        <div className="col-2 p-0 mx-auto">
          <img
            src={Ambassador_four}
            className="ambassador-image rounded mt-5"
          />
        </div>
      </div>
    </div>
  );
};
