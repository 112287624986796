import React from 'react';

const HomeTable = (props: any) => {

  return (
    <div className="mt-4">
      {props?.taskData.length > 0 ? (
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Task</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {props?.taskData?.map((task: any, index: number) => (
              <tr key={task._id}>
                <th scope="row">{index + 1}</th>
                <td>{task.courseName}</td>
                <td>
                  {task.taskName.join(", ")} {/* Joining the tasks if they are an array */}
                </td>
                <td>{task.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center mt-4">
          <p>No data found</p>
        </div>
      )}
    </div>
  );
};

export default HomeTable;
