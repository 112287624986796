import React from "react";
import { NavBar } from "../nav-bar";

const Refund = () => {
  return (
    <div className="  pt-5">
      <div className="row">
        <div className="col-md-12 ">
          <NavBar />
        </div>
      </div>
      <div className="container">
        <div className="col-md-12  mt-3 py-4 my-md-5 py-md-4 text-start">
          <h1>REFUND, CANCELLATION, PROGRAM CHANGE AND RESCHEDULING POLICY</h1>
          <p className="mt-3">
            <h4 className="mt-3">PURPOSE:</h4> This policy governs the refunds
            process and will address matters arising out of situations that may
            require processing of refunds.
          </p>
          <h4 className="mt-3">SCOPE:</h4>
          <p className="mt-3">
            The policy encompasses employees, students, and candidates that are
            in the ecosystem of requesting and/or processing refunds
          </p>
          <p className="mt-3">
            This policy does not cover situations where refunds are not approved
            or authorized by Eduexpose management
          </p>
          <h4 className="mt-3">A. Industrial training:</h4>
          <h4 className="mt-3">Online Industrial Training</h4>
          <p className="mt-3">
            SCOPE: The fees that we charge the students is only for providing
            Industrial training via live classes. Eduexpose does not charge any
            amount for the internship project phase as it is completely
            complimentary
          </p>
          <h4 className="mt-3">B. FEES</h4>
          <h4 className="mt-3">
            There are two components to Industrial Training, certification
            program fees:
          </h4>
          <ul>
            <li>Registration Charges for using platform</li>
            <li>Mentorship Fees.</li>
          </ul>
          <h4 className="mt-3">
            C. PROCEDURE FOR REFUND OR CANCELLATION OF PROGRAMS
          </h4>
          <ul>
            <li>Programs:</li>
            <li>Industrial Training Programs</li>
          </ul>
          <p className="mt-3">
            Self-Paced Industrial Training Program: All self-paced Industrial
            Training programs are non-refundable and non-transferrable under any
            circumstances. Full refunds will be issued only if Eduexpose cancels
            the complete program
          </p>
          <p className="mt-3">
            Online Industrial Training Program: All online Industrial Training
            programs are non-refundable and non-transferrable under any
            circumstances. Full refunds will be issued only if Eduexpose cancels
            the complete program
          </p>
          <p className="mt-3">
            Please note that the registration charges which is 20% of the
            program fee or Rs.1000/- + GST (whichever is higher) are
            nonrefundable at any point in time.
          </p>
          <h4 className="mt-3">Job Guarantee Programs</h4>
          <p className="mt-3">Self Paced Job Guarantee (Assistance) Program:</p>
          <p className="mt-3">
            Self paced programs are refundable only if a refund request is
            raised up to 1 week before the date of commencement and the student
            has not accessed the LMS. Any refund requests post LMS access will
            be strictly denied. Please note that the registration charges which
            is 20% of the program fee or Rs.1000/- (whichever is higher) are non
            refundable at any point in time.
          </p>
          <p className="mt-3">
            Full refunds will be issued only if Eduexpose cancels the entire
            program.
          </p>
          <h4 className="mt-3">2. Process for refund:</h4>
          <p className="mt-3">
            For all refunds please reach out to us: support@Eduexpose.in In case
            if you applicable for refund then team will contact you within 2-5
            business days. For approved refund cases Refunds Form needs to be
            filled. In 7-10 business days refund will be processed.
          </p>
          <h4 className="mt-3">How Your Refund is Returned:</h4>
          <p className="mt-3">
            If your payment came from your card, it will be refunded to that
            card. Depending on your card issuer, it may take up to 30 days to
            complete a refund.
          </p>
          <p className="mt-3">
            If your payment came from your bank account, it will be refunded to
            that bank account.
          </p>
          <p className="mt-3">
            If your payment came from some other source (instamojo, phonepe,
            googlepay etc) it will be refunded back to the same source
          </p>
          <p className="mt-3">
            Please note no cash refunds are made under any circumstances.
          </p>
          <h4 className="mt-3">CHANGE OF PROGRAM</h4>
          <ul>
            Eduexpose allows you to change your program to an alternative
            program only once using the following guidelines:
            <li>
              Eduexpose is notified in writing at least 1 week before the
              commencement of the original program.
            </li>
            <li>
              The alternative program has seats available and will be finished
              within 1 month of the original program finish date.
            </li>
            <li>
              If switching to a program of a higher value, students are required
              to pay the amount difference. However, if switching to a program
              of a lower value, Eduexpose is not liable to pay the amount
              difference. A processing fee of Rs. 500/- needs to be paid within
              3 days after Eduexpose accepts change of program request, failing
              which program change request will be cancelled and no further
              request will be entertained.
            </li>
            <li>program change is allowed only once during the program.</li>
          </ul>
          <h4 className="mt-3">Process For Change Of program:-</h4>
          <p className="mt-3">
            Contact Eduexpose at support@Eduexpose.in with your program change
            request. Once approved, fill up the “program Change Form” and submit
            it within 3 business days. Pay the processing fees + difference in
            program costs (if applicable) and submit the receipt to “
            support@Eduexpose.in ”.
          </p>
          <p className="mt-3">
            In 5-7 business days, you will get a confirmation for the program
            change request.
          </p>
          <h4 className="mt-3">D. RESCHEDULING</h4>
          <ul className="mt-3">
            1. Rescheduling by Eduexpose are governed by the following
            guidelines:
            <li className="mt-3">
              If a If a program doesn’t begin within 15 business days of the
              published commencement date, a student is entitled to either a
              full refund or can choose to join a different batch/ program.
              doesn’t begin within 15 business days of the published
              commencement date, a student is entitled to either a full refund
              or can choose to join a different batch/ program.
            </li>
            <li className="mt-3">
              Eduexpose will make all possible efforts to conduct a program.
              However, in the event of a program being rescheduled due to
              unforeseen delays on our end, Eduexpose is only responsible for
              the cost of the program. Any travel, logistics or personal
              expenses incurreddue to this program won’t be refunded.
            </li>
          </ul>
          <ul className="mt-3">
            2. Rescheduling request by customer are governed by the following
            guidelines:
            <li className="mt-3">
              The reschedule request has to be made at least 7 business days
              before the commencement of the program.
            </li>
            <li className="mt-3">
              Rescheduling will not allow the customer to change their program.
            </li>
            <li className="mt-3">
              The reschedule is subject to availability of seats.
            </li>
            <li className="mt-3">
              A rescheduling fee of Rs. 500/- must be paid within 5 business
              days from the date of approval
            </li>
          </ul>
          <h4 className="mt-3">3. Process for Rescheduling of program:</h4>
          <p className="mt-3">
            Contact Eduexpose at support@Eduexpose.in with your program
            rescheduling request. Once approved, fill up the “Reschedule program
            Form” and submit it within 3 business days. Pay the processing fees
            + difference in program costs (if applicable) and submit the receipt
            to “ support@Eduexpose.in”
          </p>
          <p className="mt-3">
            If rescheduled program is of a higher value, students are required
            to pay the amount difference.However, if switching to a program of a
            lower value, Eduexpose is not liable to pay the amount difference. A
            processing fee of Rs. 500/- needs to be paid within 3 days after
            Eduexpose acceptsreschedule request, failing which reschedule
            request will be cancelled and no further request will be
            entertained.
          </p>
          <p className="mt-3">
            In 5-7 business days you will get a confirmation for the program
            reschedule request.
          </p>
          <h4 className="mt-3">A. CANCELLATION</h4>
          <p className="mt-3">
            Eduexpose reserves the right to cancel any session or change the
            location of the session for reasons such as Instructor
            unavailability, insufficient participants or force majeure events
            such as earthquakes, fire, flood, riots, curfew, bandh, protest,
            strike, civil unrest etc. Please note our cancellation policy is
            governed by the following:
          </p>
          <p className="mt-3">
            In case Eduexpose cancels a workshop/seminar/conference, 100% refund
            will be paid to the participants.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Refund;
