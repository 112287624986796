import React from 'react'
import { NavBar } from '../components/nav-bar'
// import { TakOurWord } from '../components/home-page-components/tak-our-word'
import { Footer } from '../components/footer'
import { LearningSkills } from '../components/programs/learning-skills'
import { TrendingDomains } from '../components/home-page-components/trending-domains'
import { TrendingDomainCards } from '../components/trending-domain-cards'
import { AllDomains } from '../components/programs/all-domains'
import { FrequentlyAskedQuestions } from '../components/program-details/Faq'

export const Programs = () => {
  return (
    <div className='container-fluid scroll-bar-width-none primary-bg  pt-5'>
      <div className="row">
        <div className="col-md-12">
          <NavBar activeMenu={"programs"} />
        </div>
        <div className='border-bottom border-white mt-5 mb-4 pt-3 pb-5 text-white'>
          <LearningSkills />
        </div>
        <div className='border-bottom border-white px-2 px-md-2 px-lg-5 mb-2 pb-2 text-white'>
          <div className='fs-1 fw-bold text-start mb-5'>Trending <span className='primary-color'>programs</span></div>
          <TrendingDomainCards/>
        </div>
        <div className='border-bottom border-white my-3 pb-5 pb-md-3 text-white'>
          <AllDomains isTrending={true}/>
        </div>
        {/* <div className='border-bottom border-white my-5 pb-5'>
          <TakOurWord />
        </div> */}
        <div className='border-bottom border-white my-3 pb-3 text-white'>
          <FrequentlyAskedQuestions />
          
        </div>
        <div className='col-md-12 border-bottom border-light mt-3 text-white'>
          <Footer />
        </div>
      </div>
    </div >
  )
}
