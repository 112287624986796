import React from 'react'
import { TrendingDomainCards } from '../trending-domain-cards'
import { RewardsOfferedCards } from '../rewards-cards';
import {useNavigate} from 'react-router-dom'

export const RewardsOffered = () => {
    const navigate=useNavigate();
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
    const handleRegister=()=>{
        scrollToTop()
        navigate('/getstarted')
    }
    return (
        <div className='px-2 mx-md-5'>
            <div className='d-md-flex justify-content-between mb-5'>
                <div className='text-start'>
                    <div className='medium-header'>Rewards <span className='primary-color'>offered</span></div>
                    {/* <div className='secondary-color'>the most trending domains are the ai, data science</div> */}
                </div>
                <div className='my-4 mt-md-2 text-start me-auto me-md-0'>
                    <button className='bg-transparent text-white border border-white px-4 py-1 primary-btn'
                    onClick={()=>handleRegister()}
                    >Register now</button>
                </div>
            </div>
            <RewardsOfferedCards/>
        </div>
    )
}
