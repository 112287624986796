import React, { useState } from "react";
import { Footer } from "../components/footer";
import { AiOutlineDownload } from "react-icons/ai";
import { NavBar } from "../components/nav-bar";
import { UserService } from "../app/service/user.service";
import { toast } from "react-toastify";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import signImg from "../assets/images/img/sign.png";
import compImg from "../assets/images/img/Logo.png";
import QRCode from "qrcode"; // Import QRCode library

const VerifyCertificate = () => {
  const [email, setEmail] = useState("");
  const [certificateData, setCertificateData] = useState<any[]>([]); // State for storing multiple certificate details
  const [isShow, setIsShow] = useState(false);
  const [isCheck, setIsCheck] = useState(false);

  const dateFormat = (dateStr: any) => {
    const date = moment(dateStr);
    const formattedDate = date.format("DD/MMM/YYYY");
    return formattedDate;
  };
  const handleVerify = (e: any) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please enter an email address.");
      return;
    }

    const data = { email };

    UserService.certificatesList(data)
      .then((res) => {
        if (res.status === "Success") {
          toast.success("Certificates verified successfully.");
          setCertificateData(res.data); // Store certificate data in state (assuming it's an array)
        } else {
          toast.error(res.message);
          setCertificateData([]); // Clear the certificate data if verification fails
        }
      })
      .catch(() => {
        toast.error("An error occurred while verifying the certificates.");
        setCertificateData([]); // Clear the certificate data if there's an error
      });
  };

  const handleCerificate = () => {
    setIsShow(!isShow);
  };

  // Generate QR Code with a URL
  const generateQrCode = async (url: any) => {
    try {
      const qrCode = await QRCode.toDataURL(url);
      return qrCode;
    } catch (error) {
      console.error("QR Code generation failed", error);
    }
  };

  const handleView = async (item: any, type: any) => {
    let certificateHtml;
    const certificateUrl = `https://www.eduexpose.in/verifyCerificate`;

    // Generate QR Code and wait for it to be ready
    const dataUrl = await generateQrCode(certificateUrl);

    switch (type) {
      case "InternshipCertificate":
        certificateHtml = ReactDOMServer.renderToString(
          IntenshipT(item, dataUrl)
        );
        break;
      case "TrainingCertificate":
        certificateHtml = ReactDOMServer.renderToString(
          IntenshipC(item, dataUrl)
        );
        break;
      case "ParticipationCertificate":
        certificateHtml = ReactDOMServer.renderToString(
          IntenshipP(item, dataUrl)
        );
        break;
      default:
        certificateHtml = "";
    }

    const container1 = document.createElement("div");
    container1.style.position = "fixed";
    // container1.style.top = "-10000px";
    // container1.style.left = "-8000px";
    // container1.style.width = "100%";
    // container1.style.minHeight = "100%";
    container1.style.padding = "0px";
    // container1.style.margin = "auto";
    container1.innerHTML = certificateHtml;
    document.body.appendChild(container1);

    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Render the HTML content to canvas
    const canvas = await html2canvas(container1, {
      scale: 2, // Higher scale for better resolution
      useCORS: true, // Enable cross-origin images loading
      scrollX: 0,
      scrollY: 0,
      width: container1.scrollWidth,
      height: container1.scrollHeight,
    });

    // Clean up the temporary HTML container immediately
    document.body.removeChild(container1);

    // Convert canvas to image data
    const imgData = canvas.toDataURL("image/png");

    // Initialize jsPDF with custom dimensions
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [canvas.width, canvas.height], // Set dimensions to match canvas
    });

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Add the image to the PDF
    pdf.addImage(imgData, "PNG", 0, 0, pageWidth, pageHeight);

    // Save the PDF
    pdf.save("certificate.pdf");
  };

  const IntenshipC = (item: any, dataUrl: any) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    const startDate = dateFormat(item.startDate);
    const endDate = dateFormat(item.endDate);
    let genderValue = item.gender === "her" ? "he" : "she";
    return (
      <div className="main-section-c">
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 text-center">
              <h1 className="head-1-1 pt-4 mb-0">CERTIFICATE</h1>
              <span className="head-3-1">OF TRAINING</span>
              <p className="para-1 mt-3">
                THIS CERTIFICATE IS <br /> PROUDLY PRESENTED TO
              </p>
              <h1 className="head-2-1 pb-2 text-dark">{item.userName}</h1>
              <span className="op-8 pt-5 ">
                This is to certify that the above-mentioned candidate, has
                successfully completed the
                <b> {item.courseName}</b> training program conducted from{" "}
                <b>
                  {startDate} to {endDate}
                </b>
                .
              </span>
              <p className="op-8 pt-2 ">
                Throughout the training period, {genderValue} demonstrated a
                strong commitment to learning and exhibited a commendable
                understanding of the program.
              </p>
              <div className="d-flex justify-content-between pt-2 pe-5">
                <div>
                  <div>
                    <img src={signImg} width="150px" alt="" />
                  </div>
                  <div className="sign">
                    <span className="para-1 mt-2 text-uppercase">Director</span>
                  </div>
                </div>
                <div></div>
                <div className="mt-4 pt-2">
                  <div className="">{date}</div>
                  <div className="sign">
                    <span className="para-1 text-uppercase">Date</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
    );
  };
  const IntenshipT = (item: any, dataUrl: any) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    const startDate = dateFormat(item.startDate);
    const endDate = dateFormat(item.endDate);
    const gender = item?.gender?.toLowerCase();
    const capitalizedGender = gender.charAt(0).toUpperCase() + gender.slice(1);
    return (
      <>
        <div className="main-section">
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-8 ps-5">
                <h1 className="head-1 pt-5 mb-0">CERTIFICATE</h1>
                <span className="op-7 head-3">of Internship</span>
                <h5 className="op-7 para">Proudly awarded to</h5>
                <h1 className="head-2 pt-2 pb-2">{item.userName}</h1>

                <span className="pt-5">
                  {capitalizedGender} successfully completed program in
                  <b> {item.courseName}</b> from{" "}
                  <b>
                    {startDate} to {endDate}
                  </b>
                  . We found {item.gender} sincere, hardworking, dedicated and
                  result oriented. We take this opportunity to wish{" "}
                  {item.gender} all the best for future.
                </span>
                <div className="d-flex justify-content-between mt-5 pt-5">
                  <div>
                    <div>
                      <img src={signImg} width="150px" alt="" />
                    </div>
                    <div className="sign">
                      <span className="op-7 mt-2">Director</span>
                    </div>
                  </div>
                  <div>
                    <div className="">
                      <div className="text-center">
                        <img src={dataUrl} width="80px" alt="QR-code" />
                      </div>
                      <div className="text-center">
                        <h6 className="op-7 mb-0">Sacn to validate</h6>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 pt-2">
                    <div className="">{date}</div>

                    <div className="sign">
                      <span className="op-7">Date</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const IntenshipP = (item: any, dataUrl: any) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    const startDate = dateFormat(item.startDate);
    const endDate = dateFormat(item.endDate);
    return (
      <>
        <div className="main-section-p">
          <div className="container pt-5 pb-5">
            <div className="row p-0">
              <div className="col-md-8 ps-5 text-center">
                <div className="mb-3">
                  <img src={compImg} width="250px" alt="" />
                </div>
                <span className="head-4">CERTIFICATE OF PARTICIPATION</span>
                <p className="para-1 mt-3">
                  THIS CERTIFICATE IS PROUDLY PRESENTED TO:
                </p>
                <div className="d-flex justify-content-center mb-2">
                  <h1
                    className="head-2 pt-2 pb-2 sign-2"
                    style={{ color: "#b4953f" }}
                  >
                    {item.userName}
                  </h1>
                </div>

                <span className="op-8 ">
                  For {item.gender} active and invaluable participation in the
                  <b> {item.courseName}</b> from{" "}
                  <b>
                    {startDate} to {endDate}
                  </b>{" "}
                  and {item.gender} successfully completed the program.
                </span>
                <div className="d-flex justify-content-between mt-5 pt-5 ps-5 pe-5">
                  <div>
                    <div>
                      <img src={signImg} width="150px" alt="" />
                    </div>
                    <div className="sign-1">
                      <span className="para-1 mt-2 text-uppercase">
                        Director
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="">
                      <div className="text-center">
                        <img src={dataUrl} width="80px" alt="QR-code" />
                      </div>
                      <div className="text-center">
                        <h6 className="op-7 mb-0">Sacn to validate</h6>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 pt-2">
                    <div className="">{date}</div>

                    <div className="sign-1">
                      <span className="para-1 text-uppercase">Date</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleClick = () => {
    setIsCheck(!isCheck);
  };

  return (
    <>
      <div className="container-fluid scroll-bar-width-none primary-bg min-vh-100 d-flex flex-column">
        <div className="row">
          <div className="col-md-12">
            <NavBar activeMenu={"home"} />
          </div>
        </div>

        {/* Main content area */}
        <div className=" d-flex align-items-center justify-content-center">
          {/* Show the form when there is no certificate data */}
          {!certificateData.length && (
            <form
              className="mb-3 text-white d-flex flex-column align-items-center"
              onSubmit={handleVerify} // Form submission handled here
              style={{ width: "100%", maxWidth: "300px" }} // Center the form and set max width
            >
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <h5 className="text-white text-center">
                Authenticate Certificate
              </h5>

              {/* Updated Checkbox Section */}
              <div className="form-check d-flex align-items-center mx-auto mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  style={{ marginRight: "8px" }} // Adjust spacing here
                  onClick={handleClick}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  I Submit Project
                </label>
              </div>

              {/* Show the email input if the checkbox is selected */}
              {isCheck && (
                <div className="p-4 w-100 text-center">
                  <label className="mb-3">Enter Email Address</label>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Update state with input value
                    className="form-control mt-2"
                    style={{
                      border: "1px solid white", // Input box border
                      borderRadius: "8px", // Input box rounded corners
                      backgroundColor: "transparent",
                      color: "white",
                      padding: "10px", // Padding for better look
                    }}
                  />
                  <button
                    type="submit"
                    className="px-3 py-1 bg-transparent text-white border border-white primary-btn mt-4"
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                    }}
                  >
                    Validate
                  </button>
                </div>
              )}
            </form>
          )}

          {certificateData.length > 0 && (
            <div className="p-4 mt-4 text-white text-center w-100 d-flex flex-column align-items-center justify-content-center">
              <br />
              <br />
              <br />

              <h4 className="mt-5">Authenticate Verified</h4>
              <table
                className="table table-bordered text-white mt-3 text-center"
                style={{ maxWidth: "800px" }}
              >
                <thead>
                  <tr>
                    <th>Course Name</th>
                    <th>Certificate Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center mx-auto">
                  {certificateData.map((certificate, index) => (
                    <tr key={index}>
                      <td>{certificate.courseName}</td>
                      <td>
                        {certificate.certificateType.map(
                          (type: any, i: any) => (
                            <div key={i}>{type}</div>
                          )
                        )}
                      </td>
                      <td>
                        {/* Remove ms-5 to prevent extra margin and ensure it's centered */}
                        {certificate.certificateType.map(
                          (type: any, i: any) => (
                            <button
                              key={i}
                              className="bg-button mt-2 mx-auto"
                              style={{
                                borderRadius: "5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center", // Centers content horizontally
                                textAlign: "center", // Centers the text inside the button
                                gap: "5px",
                                width: "125px",
                                marginBottom: "5px",
                              }}
                              onClick={() => handleView(certificate, type)}
                            >
                              View <AiOutlineDownload />
                            </button>
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Footer at the bottom */}
        <div className="row mt-auto">
          <div className="col-md-12 text-white">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyCertificate;
