import React from 'react'
import { NavBar } from '../components/nav-bar'
import { TakOurWord } from '../components/home-page-components/tak-our-word'
import { Footer } from '../components/footer'
import { GetAnOpportunity } from '../components/ambassador/get-an-opportunity'
import { AmbassdorWorks } from '../components/ambassador/ambassdor-works'
import { RewardsOffered } from '../components/ambassador/rewards';
import GoogleImgae from "./../assets/images/google.png";
import TakeOurSectionRightImage from "./../assets/images/take-our-section-right-image.jpg";
import { FrequentlyAskedQuestions } from '../components/programs/faq'
import infoys from "./../assets/images/reviews/Infosys.jpeg";
import ibm from "./../assets/images/reviews/ibm.png";
import byjus from "./../assets/images/reviews/byjus.jpeg";


interface Props{
  ambassadorReviews?:boolean;
}
export const Ambassador = (props:Props) => {
  const testimonials = [
    {
      text: "As a brand ambassador for Eduexpose, it was an amazing experience! I got to share cool internship opportunities with others, making studying more fun. Plus, the team was supportive and friendly. Being an ambassador made me feel proud and excited about learning.Thanks to my guide sujay.",
      name: "Shresta",
      company: "SRM",
      image: TakeOurSectionRightImage, // Make sure to import your image
      companyImage:infoys
    },
    {
      text: "Being a brand ambassador for Eduexpose was fantastic! I got to spread the word about awesome learning experiences and help students succeed. It was a rewarding experience, and I'm grateful for the opportunity and i got some incentives aswell .your efforts pays you well.",
      name: "Sanketh",
      company: "LPU",
      image: TakeOurSectionRightImage, // Make sure to import your image
      companyImage:GoogleImgae
    },
    {
      text: "Working as a brand ambassador for Eduexpose was an incredible journey! I had the chance to introduce fellow students to innovative learning solutions and see firsthand how it transformed their education. Being part of this team was both fulfilling and inspiring! ",
      name: "Aadya",
      company: "MANIPAL",
      image: TakeOurSectionRightImage, // Make sure to import your image
      companyImage:byjus
    },
    {
      text: "Being a brand ambassador for Eduexpose was an absolute blast! Firstly i've joined the course and  their courses made me learning easier and more exciting and then i decided to grab an opportunity as an ambassador . It was rewarding to see my friends benefit from the opportunities I promoted. ",
      name: "Chaitra",
      company: "SVREC",
      image: TakeOurSectionRightImage, // Make sure to import your image
      companyImage:ibm
    },
  ];
  return (
    <div className='container-fluid scroll-bar-width-none primary-bg  pt-5' style={{ backgroundColor: "#1d130f", color: "white" }}>
    <div className="row">
      <div className="col-md-12">
        <NavBar activeMenu={"ambassador"} />
      </div>
      <div className='border-bottom border-white mt-5 mb-3 pb-5 text-center text-white'>
        <GetAnOpportunity />
      </div>
      <div className='border-bottom border-white mt-0 mb-3 pb-3 text-center text-white'>
        <AmbassdorWorks />
      </div>
      <div className='border-bottom border-white my-3 pb-3 text-white'>
        <RewardsOffered />
      </div>
      <div className='border-bottom border-white my-3 pb-3 text-white'>
        <TakOurWord ambassadorReviews={true} testimonials={testimonials}/>
      </div>
      <div className='border-bottom border-white mt-3 pb-3'>
      <FrequentlyAskedQuestions />
      </div>
      <div className='col-md-12 border-bottom border-light mt-3 text-white'>
        <Footer />
      </div>
    </div>
  </div >
  )
}
