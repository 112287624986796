import React, { useContext, useEffect, useState } from "react";
export const AppContext = React.createContext({});
export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: any) => {
  const [usersData, setUserData] = useState<any>(() => {
    const savedData = sessionStorage.getItem("loginData");
    return savedData ? JSON.parse(savedData) : {};
  });
  let loaderElement: any;
  let count: number = 0;
  const showLoader = () => {
    if (!count) {
      loaderElement = document?.getElementById("loader-block");
      loaderElement.style.display = "block";
    }
    count++;
  };
  const hideLoader = () => {
    count--;
    if (!count) {
      setTimeout(() => {
        loaderElement = document?.getElementById("loader-block");
        loaderElement.style.display = "none";
      }, 0);
    }
  };
  const handleLogin = (data: any) => {
    setUserData(data);
    sessionStorage.setItem("loginData", JSON.stringify(data));
    sessionStorage.setItem("token", data.token);
  };

  return (
    <AppContext.Provider value={{ showLoader, hideLoader,handleLogin,usersData }}>
      {" "}
      {children}
    </AppContext.Provider>
  );
};
