import React, { useState } from 'react';

export const FrequentlyAskedQuestions = () => {
    const [activeAccordion, setActiveAccordion] = useState(null);

    const toggleAccordion = (id: any) => {
        if (activeAccordion === id) {
            setActiveAccordion(null);
        } else {
            setActiveAccordion(id);
        }
    };

    return (
        <div className='px-2 mx-md-5 text-center'>
            <div className='col-md-12'>
                <div className='medium-header '>Frequently Asked <span className='primary-color'>Questions</span></div>
                <div className='questions-list w-75 mx-auto bg-transparent my-4'>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item border">
                            <h2 className="accordion-header bg-transparent border-bottom-0">
                                <button
                                    className={`accordion-button border-bottom-0 border-bottom-transparent faq-fontfamily faq-font ${activeAccordion === 'flush-collapseOne' ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion('flush-collapseOne')}
                                >
                                    What kind of support will be provided during the&nbsp;<span className='primary-color tab-cnt-span ps-2'>training or internship period?</span>
                                </button>
                            </h2>
                            <div
                                id="flush-collapseOne"
                                className={`accordion-collapse border-top-0 collapse ${activeAccordion === 'flush-collapseOne' ? 'show' : ''}`}
                            >
                                <div className="accordion-body">
                                    Support can include mentorship,feedback sessions,networking opportunities, and resources for personal and professional development.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border">
                            <h2 className="accordion-header border-bottom-0">
                                <button
                                    className={`accordion-button border-bottom-0 faq-font faq-fontfamily ${activeAccordion === 'flush-collapseTwo' ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion('flush-collapseTwo')}
                                >
                                    Can I apply for multiple&nbsp;<span className='primary-color tab-cnt-span ps-2'>training and internship programs at once?</span>
                                </button>
                            </h2>
                            <div
                                id="flush-collapseTwo"
                                className={`accordion-collapse collapse ${activeAccordion === 'flush-collapseTwo' ? 'show' : ''}`}
                            >
                                <div className="accordion-body">
                                    No,As per company noms you can apply for only 1 or to domains not more than that.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border">
                            <h2 className="accordion-header border-bottom-0">
                                <button
                                    className={`accordion-button border-bottom-0 faq-font faq-fontfamily ${activeAccordion === 'flush-collapseThree' ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion('flush-collapseThree')}
                                >
                                    What are the eligibility criteria for applying for&nbsp;
                                    <span className='primary-color tab-cnt-span ps-2'>training and internship programs?</span>
                                </button>
                            </h2>
                            <div
                                id="flush-collapseThree"
                                className={`accordion-collapse collapse ${activeAccordion === 'flush-collapseThree' ? 'show' : ''}`}
                            >
                                <div className="accordion-body">
                                    Eligibility criteria vary depending on the program, but students who wants to learn and grow in technical side are eligible for the same.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border">
                            <h2 className="accordion-header border-bottom-0">
                                <button
                                    className={`accordion-button border-bottom-0  faq-fontfamily faq-font ${activeAccordion === 'flush-collapseFour' ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion('flush-collapseFour')}
                                >
                                    Will I receive a certificate upon completion of the&nbsp;<span className='primary-color tab-cnt-span ps-2'>training or internship program?</span>
                                </button>
                            </h2>
                            <div
                                id="flush-collapseThree"
                                className={`accordion-collapse collapse ${activeAccordion === 'flush-collapseFour' ? 'show' : ''}`}
                            >
                                <div className="accordion-body">
                                    Yes,after successfully completion of your internship you will be elligible for participation ,training and internship completion certifications.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border">
                            <h2 className="accordion-header border-bottom-0">
                                <button
                                    className={`accordion-button border-bottom-0 faq-fontfamily faq-font ${activeAccordion === 'flush-collapseFive' ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion('flush-collapseFive')}
                                >
                                    Is there any extra&nbsp;<span className='primary-color tab-cnt-span ps-2'>charges for internship?</span>
                                </button>
                            </h2>
                            <div
                                id="flush-collapseFive"
                                className={`accordion-collapse collapse ${activeAccordion === 'flush-collapseFive' ? 'show' : ''}`}
                            >
                                <div className="accordion-body">
                                    Post Completion of registration,the companies don't charge any extra amount.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border">
                            <h2 className="accordion-header border-bottom-0">
                                <button
                                    className={`accordion-button border-bottom-0 faq-font faq-fontfamily faq-fontfamily ${activeAccordion === 'flush-collapseSix' ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion('flush-collapseSix')}
                                >
                                    What will happend if we miss&nbsp;<span className='primary-color tab-cnt-span ps-2'>live sessions?</span>
                                </button>
                            </h2>
                            <div
                                id="flush-collapseSix"
                                className={`accordion-collapse collapse ${activeAccordion === 'flush-collapseSix' ? 'show' : ''}`}
                            >
                                <div className="accordion-body">
                                    Since all sessions are recorded and shared via mail, you can access them at anytime.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border">
                            <h2 className="accordion-header border-bottom-0">
                                <button
                                    className={`accordion-button border-bottom-0 faq-fontfamily ${activeAccordion === 'flush-collapseSeven' ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion('flush-collapseSeven')}
                                >
                                    Are there any additional charges other than 3500/- &nbsp;<span className='primary-color tab-cnt-span ps-2'>for Training and Internships?</span>
                                </button>
                            </h2>
                            <div
                                id="flush-collapseSix"
                                className={`accordion-collapse collapse ${activeAccordion === 'flush-collapseSeven' ? 'show' : ''}`}
                            >
                                <div className="accordion-body">
                                    No. it will be included with everything.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border">
                            <h2 className="accordion-header border-bottom-0">
                                <button
                                    className={`accordion-button border-bottom-0 faq-fontfamily ${activeAccordion === 'flush-collapseEight' ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion('flush-collapseEight')}
                                >
                                    What is the&nbsp;<span className='primary-color tab-cnt-span ps-2'>duration of this internship?</span>
                                </button>
                            </h2>
                            <div
                                id="flush-collapseSix"
                                className={`accordion-collapse collapse ${activeAccordion === 'flush-collapseEight' ? 'show' : ''}`}
                            >
                                <div className="accordion-body">
                                    2 months and you will be working with companies mentors
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border">
                            <h2 className="accordion-header border-bottom-0">
                                <button
                                    className={`accordion-button border-bottom-0 faq-fontfamily ${activeAccordion === 'flush-collapseNine' ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion('flush-collapseNine')}
                                >
                                    What is the&nbsp;<span className='primary-color tab-cnt-span ps-2'>last date for registration?</span>
                                </button>
                            </h2>
                            <div
                                id="flush-collapseNine"
                                className={`accordion-collapse collapse ${activeAccordion === 'flush-collapseNine' ? 'show' : ''}`}
                            >
                                <div className="accordion-body">
                                    the last date of registration depends on the slots availability , where the students are still applying ,you can block your slot asap
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border">
                            <h2 className="accordion-header border-bottom-0">
                                <button
                                    className={`accordion-button border-bottom-0 faq-fontfamily ${activeAccordion === 'flush-collapseTen' ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion('flush-collapseTen')}
                                >
                                    Where the&nbsp;<span className='primary-color tab-cnt-span ps-2'>sessions will be held?</span>
                                </button>
                            </h2>
                            <div
                                id="flush-collapseNine"
                                className={`accordion-collapse collapse ${activeAccordion === 'flush-collapseTen' ? 'show' : ''}`}
                            >
                                <div className="accordion-body">
                                    the sessions will be held on companies dashboard ,The credentials will be shared to your after successful completion of registration
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my-4 secondary-color'>Have a question that is not answered? You can contact us at <span className='fw-bold fs-6 text-white'>support@eduexpose.in</span></div>
            </div>
        </div>
    );
}
