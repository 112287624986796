import React from "react";
import Pen from "./../../assets/images/pen.png";
import { TbRotateRectangle } from "react-icons/tb";

export const IsProgram = () => {
  const handleProgram = () => {
    window.open(
      "https://eduexpose.mojo.page/training-program",
      "_blank"
    );
  };
  return (
    <div className="px-2 mx-md-5">
      <div className="d-md-flex justify-content-between mb-3">
        <div className="text-start">
          <div className="fs-1 fw-bold">
            This <span className="primary-color">program</span> includes
          </div>
        </div>
        <div className="my-4 mt-md-2 text-start me-auto me-md-0">
          <button
            className="bg-transparent text-white border border-white px-4 py-1 primary-btn"
            onClick={handleProgram}
          >
            Start program
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 d-flex  my-2">
          <div className="">
            {/* <img src={Pen} /> */}
            <TbRotateRectangle style={{ fontSize: "58px" }} />
          </div>
          <div className="mx-3 text-start">
            <div className="fs-5 fw-bold my-2">
              Basic-advanced level training sessions
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex  my-2">
          <div className="">
            {/* <img src={Pen} /> */}
            <TbRotateRectangle style={{ fontSize: "58px" }} />
          </div>
          <div className="mx-3 text-start">
            <div className="fs-5 fw-bold my-2">
              Downloadable resources of technology
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex  my-2">
          <div className="">
            {/* <img src={Pen} /> */}
            <TbRotateRectangle style={{ fontSize: "58px" }} />
          </div>
          <div className="mx-3 text-start">
            <div className="fs-5 fw-bold my-2">Coding exercise</div>
          </div>
        </div>
        <div className="col-md-4 d-flex  my-2">
          <div className="">
            {/* <img src={Pen} /> */}
            <TbRotateRectangle style={{ fontSize: "58px" }} />
          </div>
          <div className="mx-3 text-start">
            <div className="fs-5 fw-bold my-2">Live industrial meets</div>
          </div>
        </div>
        <div className="col-md-4 d-flex  my-2">
          <div className="">
            {/* <img src={Pen} /> */}
            <TbRotateRectangle style={{ fontSize: "58px" }} />
          </div>
          <div className="mx-3 text-start">
            <div className="fs-5 fw-bold my-2">Access on mobile & computer</div>
          </div>
        </div>
        <div className="col-md-4 d-flex  my-2">
          <div className="">
            {/* <img src={Pen} /> */}
            <TbRotateRectangle style={{ fontSize: "58px" }} />
          </div>
          <div className="mx-3 text-start">
            <div className="fs-5 fw-bold my-2">Certificate of completion</div>
          </div>
        </div>
      </div>
      <div className="d-md-flex justify-content-between mb-3 mt-3">
        <div className="text-start">
          <div className="fs-1 fw-bold">
            <span className="primary-color">Requirements</span>
          </div>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-md-6 d-flex  my-3 text-center">
          <div className="">
            <TbRotateRectangle style={{ fontSize: "58px" }} />
          </div>
          <div className="mx-3 text-start">
            <div className="fs-5 fw-bold my-2">
              Have a good internet connection
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex  my-2">
          <div className="">
            <TbRotateRectangle style={{ fontSize: "58px" }} />
          </div>
          <div className="mx-3 text-start">
            <div className="fs-5 fw-bold my-2">
              Get ready to get live exposure{" "}
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex  my-2">
          <div className="">
            <TbRotateRectangle style={{ fontSize: "58px" }} />
          </div>
          <div className="mx-3 text-start">
            <div className="fs-5 fw-bold my-2">
              Prepare to build technologies
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex  my-2">
          <div className="">
            <TbRotateRectangle style={{ fontSize: "58px" }} />
          </div>
          <div className="mx-3 text-start">
            <div className="fs-5 fw-bold my-2">
              The student should be pursuing engineering ,degree or polytechnic
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
