import React, { useState } from "react";
import { FaPlay } from "react-icons/fa"; // Import the play icon
import { Accordion, Card } from "react-bootstrap"; // Import Bootstrap components

interface CourseData {
  image: string;
  title: string;
  description: string;
  curriculum: {
    category: string;
    topics: string[];
  }[];
}

interface CmsCourseDataProps {
  courseData: any;
  lmsData: any[]; // Ensure you type this properly according to your lmsData structure
}

const CmsCourseData: React.FC<CmsCourseDataProps> = (props) => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const playVideo = (videoUrl: string) => {
    setSelectedVideo(videoUrl);
  };

  // Helper function to detect YouTube URL
  const isYouTubeUrl = (url: string) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  // Helper function to extract YouTube video ID from a URL
  const getYouTubeEmbedUrl = (url: string) => {
    const videoId = url.split("v=")[1]?.split("&")[0];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <div className="container mt-4">
      <div className="row">
        {/* Left column: Course Curriculum */}
        <div className="col-md-4">
          <div className="curriculum">
            <h5>Course Videos</h5>
            <Accordion
              defaultActiveKey="0"
              id="accordionExample"
              className="primary-bg"
              style={{ borderRadius: "10px" }}
            >
              {props?.lmsData?.map((item: any, index: number) => (
                <Accordion.Item eventKey={`${index}`} key={index}>
                  <Accordion.Header className="custom-accordion-header">
                    {item.title}
                  </Accordion.Header>
                  <Accordion.Body className="custom-accordion-body">
                    {item.uploadVideo.map(
                      (videoUrl: string, urlIndex: number) => (
                        <div key={urlIndex} className="video-item mb-2 text-white">
                          <button
                            onClick={() => playVideo(videoUrl)}
                            className="btn btn-link play-button text-left p-0"
                          >
                            <FaPlay className="me-2 play-icon ms-0 text-white" />
                            <span className="video-link">{videoUrl}</span>
                          </button>
                        </div>
                      )
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>

        {/* Right column: Video or Course Info */}
        <div className="col-md-8">
          {!selectedVideo && (
            <div className="course-info">
              <img
                src={props.courseData?.image}
                alt={props.courseData?.title}
                className="mb-3 img-fluid border-top-0 border"
                style={{ borderRadius: "10px" }}
              />
              <h4>{props?.courseData?.courseName}</h4>
              <p>{props?.courseData?.description}</p>
            </div>
          )}

          {selectedVideo && (
            <div className="video-player mt-3">
              {isYouTubeUrl(selectedVideo) ? (
                <iframe
                  width="100%"
                  height="500"
                  src={getYouTubeEmbedUrl(selectedVideo)}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube video player"
                ></iframe>
              ) : (
                <video width="100%" controls>
                  <source src={selectedVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CmsCourseData;
