import React, { useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const CareerGuidancesAccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [formData, setFormData] = useState<any>({
    statusReply: "",
  });
  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.statusReply) {
      newErrors.statusReply = "Reply is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validate()) return;

    const payload = {
      statusReply: formData.statusReply,
      careerId: state?._id,
    };

    AdminService.updateCareerGuidance(payload).then((res) => {
      if (res.status === "Fail") {
        toast.error(res.error);
      } else {
        toast.success("Successfully Registered");
        navigate("/support/careerGuidances");
      }
    });
  };

  const handleCancel = () => {
    navigate("/support/careerGuidances");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Career Guidances Reply</b>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Add Reply</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.statusReply ? "is-invalid" : ""
                    }`}
                    placeholder="Add title"
                    name="statusReply"
                    onChange={handleInput}
                  />
                  {errors.statusReply && (
                    <div className="invalid-feedback">{errors.statusReply}</div>
                  )}
                </div>
              </div>
              {/* <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Description</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.description ? "is-invalid" : ""
                    }`}
                    placeholder="Add Description"
                    name="description"
                    onChange={handleInput}
                  />
                  {errors.description && (
                    <div className="invalid-feedback">{errors.description}</div>
                  )}
                </div>
              </div> */}
            </div>
            <div className="mt-3">
              <button type="submit" className="btn btn-primary me-2">
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareerGuidancesAccess;
