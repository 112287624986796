import { DataTableCol } from "../../../components/tables/types";

export const tasksListDataGridCols: DataTableCol[] = [
    {
        title: 'Batch Name',
        control: 'batchName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Course Name',
        control: 'courseName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        canShowColumn: true
    },
];